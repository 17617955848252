export default {
  awards_one: {
    en: 'Award',
    ja: 'Award',
    et: 'Award',
    lt: 'Award',
    ph: 'Award',
    ch: 'Award',
  },
  awards_other: {
    en: 'Awards',
    ja: 'Awards',
    et: 'Awards',
    lt: 'Awards',
    ph: 'Awards',
    ch: 'Awards',
  },
  Ago: {
    en: 'Ago',
    ja: '前',
    et: 'tagasi',
    lt: 'Prieš',
    ph: 'Ago',
    ch: 'Ago',
  },
  'All Rights Reserved.': {
    en: 'All Rights Reserved.',
    ja: 'All Rights Reserved.',
    et: 'Kõik õigused reserveeritud',
    lt: 'Visos teisės saugomos',
    ph: 'All Rights Reserved.',
    ch: 'All Rights Reserved.',
  },
  autoLogout: {
    en: 'Your session has expired. Please log in again.',
    ja: 'Your session has expired. Please log in again.',
    et: 'Your session has expired. Please log in again.',
    lt: 'Your session has expired. Please log in again.',
    ph: 'Your session has expired. Please log in again.',
    ch: 'Your session has expired. Please log in again.',
  },
  Badge: {
    en: 'Badge',
    ja: 'バッジ',
    et: 'Märk',
    lt: 'Ženkliukas',
    ph: 'Badge',
    ch: 'Badge',
  },
  badgeEarned: {
    en: "Woohoo! You've earned the <1>{{name}}</1> badge!",
    ja: 'やった！バッジ <1>{{name}}</1> badgeを獲得しました！',
    et: 'Juhuu! Sa oled teeninud <1>{{name}}</1> märgi!',
    lt: 'Valio, užsitarnavai ženkliuką <1>{{name}}</1>!',
    ph: "Woohoo! You've earned the <1>{{name}}</1> badge!",
    ch: "Woohoo! You've earned the <1>{{name}}</1> badge!",
  },
  Badges: {
    en: 'Badges',
    ja: 'バッジ',
    et: 'Märgid',
    lt: 'Ženkliukai',
    ph: 'Badges',
    ch: 'Badges',
  },
  badges_one: {
    en: 'Badge',
    ja: 'バッジ',
    et: 'Märk',
    lt: 'Ženkliukas',
    ph: 'Badge',
    ch: 'Badge',
  },
  badges_other: {
    en: 'Badges',
    ja: 'バッジ',
    et: 'Märgid',
    lt: 'Ženkliukai',
    ph: 'Badges',
    ch: 'Badges',
  },
  'Book a Workshop': {
    en: 'Book a Workshop',
    ja: 'ワークショップを予約',
    et: 'Registreeri töötuppa',
    lt: 'Rezervuok dirbtuves',
    ph: 'Book a Workshop',
    ch: 'Book a Workshop',
  },
  Booking: {
    en: 'Booking',
    ja: '予約',
    et: 'Broneering',
    lt: 'Užsakymai',
    ph: 'Booking',
    ch: 'Booking',
  },
  bytes: {
    en: 'bytes',
    ja: 'バイト',
    et: 'baiti',
    lt: 'baitai',
    ph: 'bytes',
    ch: 'bytes',
  },
  Copyright: {
    en: 'Copyright',
    ja: '商標登録',
    et: 'Autoriõigused',
    lt: 'Autorinės teisės',
    ph: 'Copyright',
    ch: 'Copyright',
  },
  changeLanguage: {
    en: 'Change Language',
    ja: '言語の変更',
    et: 'Muuda keelt',
    lt: 'Pakeisti kalbą',
    ph: 'Change Language',
    ch: '更换语言',
  },
  Challenges: {
    en: 'Challenges',
    ja: 'Challenges',
    et: 'Challenges',
    lt: 'Challenges',
    ph: 'Challenges',
    ch: 'Challenges',
  },
  Chat: {
    en: 'Chat',
    ja: 'チャット',
    et: 'Vestlus',
    lt: 'Kalbėtis',
    ph: 'Chat',
    ch: 'Chat',
  },
  coin_one: {
    en: '{{ count }} coin',
    ja: '{{ count }} coin', // Need translations
    et: '{{ count }} coin', // Need translations
    lt: '{{ count }} coin', // Need translations
    ph: '{{ count }} coin',
    ch: '{{ count }} coin',
  },
  coin_other: {
    en: '{{ count }} coins',
    ja: '{{ count }} coins', // Need translations
    et: '{{ count }} coins', // Need translations
    lt: '{{ count }} coins', // Need translations
    ph: '{{ count }} coins',
    ch: '{{ count }} coins',
  },
  challenges_one: {
    en: 'Challenge',
    ja: 'Challenge',
    et: 'Challenge',
    lt: 'Challenge',
    ph: 'Challenge',
    ch: 'Challenge',
  },
  challenges_other: {
    en: 'Challenges',
    ja: 'Challenges',
    et: 'Challenges',
    lt: 'Challenges',
    ph: 'Challenges',
    ch: 'Challenges',
  },
  challenges_zero: {
    en: 'Challenges',
    ja: 'Challenges',
    et: 'Challenges',
    lt: 'Challenges',
    ph: 'Challenges',
    ch: 'Challenges',
  },
  created_one: {
    en: 'Created {{count}} Project',
    ja: '作成したプロジェクト数 {{count}}',
    et: 'Loonud {{count}} projekti',
    lt: 'Sukurta {{count}} projektas',
    ph: 'Created {{count}} Project',
    ch: 'Created {{count}} Project',
  },
  created_other: {
    en: 'Created {{count}} Projects',
    ja: '作成したプロジェクト数 {{count}}',
    et: 'Loonud {{count}} projekti',
    lt: 'Sukurta {{count}} projektų',
    ph: 'Created {{count}} Projects',
    ch: 'Created {{count}} Projects',
  },
  created_zero: {
    en: 'Created {{count}} Project',
    ja: '作成したプロジェクト数 {{count}}',
    et: 'Loonud {{count}} projekti',
    lt: 'Sukurta {{count}} projektų',
    ph: 'Created {{count}} Project',
    ch: 'Created {{count}} Project',
  },
  createAccount: {
    en: 'Create Account',
    ja: 'アカウント登録',
    et: 'Loo konto',
    lt: 'Sukurti paskyrą',
    ph: 'Create Account',
    ch: '注册户口',
  },
  'Connect and Chat': {
    en: 'Connect and Chat',
    ja: 'Connect and Chat', // Need translations
    et: 'Connect and Chat', // Need translations
    lt: 'Connect and Chat', // Need translations
    ph: 'Connect and Chat',
    ch: 'Connect and Chat',
  },
  Creators: {
    en: 'Creators',
    ja: 'Creators',
    et: 'Creators',
    lt: 'Creators',
    ph: 'Creators',
    ch: 'Creators',
  },
  Year: {
    en: 'Year',
    ja: '年',
    et: 'Aasta',
    lt: 'Metai',
    ph: 'Year',
    ch: '年',
  },
  Years: {
    en: 'Years',
    ja: '年',
    et: 'Aastaid',
    lt: 'Metai',
    ph: 'Years',
    ch: '年',
  },
  Month: {
    en: 'Month',
    ja: '月',
    et: 'Kuu',
    lt: 'Mėnuo',
    ph: 'Month',
    ch: '月',
  },
  Months: {
    en: 'Months',
    ja: '月',
    et: 'Kuud',
    lt: 'Mėnesių',
    ph: 'Months',
    ch: '月',
  },
  Day: {
    en: 'Day',
    ja: '日',
    et: 'Päev',
    lt: 'Dieną',
    ph: 'Day',
    ch: '日',
  },
  Days: {
    en: 'Days',
    ja: '日',
    et: 'Päeva',
    lt: 'Dienas',
    ph: 'Days',
    ch: '日',
  },
  earnedBadges_one: {
    en: 'Earned {{count}} Badge',
    ja: '獲得したバッジ数 {{count}}',
    et: 'Teeninud {{count}} märgi',
    lt: 'Užtarnauta {{count}} ženkliukas',
    ph: 'Earned {{count}} Badge',
    ch: 'Earned {{count}} Badge',
  },
  earnedBadges_other: {
    en: 'Earned {{count}} Badges',
    ja: '獲得したバッジ数 {{count}}',
    et: 'Teeninud {{count}} märki',
    lt: 'Užtarnauta {{count}} ženkliukų',
    ph: 'Earned {{count}} Badges',
    ch: 'Earned {{count}} Badges',
  },
  earnedBadges_zero: {
    en: 'Earned {{count}} Badge',
    ja: '獲得したバッジ数 {{count}}',
    et: 'Teeninud {{count}} märki',
    lt: 'Užtarnauta {{count}} ženkliukų',
    ph: 'Earned {{count}} Badge',
    ch: 'Earned {{count}} Badge',
  },
  completedChallenges_one: {
    en: 'Completed {{count}} Challenge',
    ja: 'Completed {{count}} Challenge',
    et: 'Completed {{count}} Challenge',
    lt: 'Completed {{count}} Challenge',
    ph: 'Completed {{count}} Challenge',
    ch: 'Completed {{count}} Challenge',
  },
  completedChallenges_other: {
    en: 'Completed {{count}} Challenges',
    ja: 'Completed {{count}} Challenges',
    et: 'Completed {{count}} Challenges',
    lt: 'Completed {{count}} Challenges',
    ph: 'Completed {{count}} Challenges',
    ch: 'Completed {{count}} Challenges',
  },
  completedChallenges_zero: {
    en: 'Completed {{count}} Challenges',
    ja: 'Completed {{count}} Challenges',
    et: 'Completed {{count}} Challenges',
    lt: 'Completed {{count}} Challenges',
    ph: 'Completed {{count}} Challenges',
    ch: 'Completed {{count}} Challenges',
  },
  'Edit Profile': {
    en: 'Edit Profile',
    ja: 'プロフィールを編集',
    et: 'Profiili muutmine',
    lt: 'Keisti profilį',
    ph: 'Edit Profile',
    ch: 'Edit Profile',
  },
  Events: {
    en: 'Events',
    ja: 'イベント',
    et: 'Sündmused',
    lt: 'Renginiai',
    ph: 'Events',
    ch: 'Events',
  },
  'Events and Workshops': {
    en: 'Events and Workshops',
    ja: 'イベントとワークショップ',
    et: 'Sündmused ja töötoad',
    lt: 'Renginiai ir dirbtuvės',
    ph: 'Events and Workshops',
    ch: 'Events and Workshops',
  },
  Hour: {
    en: 'Hour',
    ja: '時間',
    et: 'Tund',
    lt: 'Valandą',
    ph: 'Hour',
    ch: '时',
  },
  Hours: {
    en: 'Hours',
    ja: '時間',
    et: 'Tundi',
    lt: 'Valandas',
    ph: 'Hours',
    ch: '时',
  },
  'Icons made by': {
    en: 'Icons made by',
    ja: 'アイコン製作',
    et: 'Ikoonide autor:',
    lt: 'Ikonėles sukūrė',
    ph: 'Icons made by',
    ch: 'Icons made by',
  },
  language: {
    en: 'English',
    ja: '日本語',
    et: 'Estonian',
    lt: 'Anglų kalba',
    ph: 'English',
    ch: '华语',
  },
  login: {
    en: 'Login',
    ja: 'ログイン',
    et: 'Logi sisse',
    lt: 'Prisijungti',
    ph: 'Login',
    ch: '登入',
  },
  logout: {
    en: 'Logout',
    ja: 'ログアウト',
    et: 'Logi välja',
    lt: 'Atsijungti',
    ph: 'Logout',
    ch: '登出',
  },
  Logout: {
    en: 'Logout',
    ja: 'ログアウト',
    et: 'Logi välja',
    lt: 'Atsijungti',
    ph: 'Logout',
    ch: 'Logout',
  },
  mail: {
    en: 'Mail Templates',
    ja: 'メールのテンプレート',
    et: 'E-maili mustandid',
    lt: 'Laiško pavyzdys',
    ph: 'Mail Templates',
    ch: '电邮模板',
  },
  Minute: {
    en: 'Minute',
    ja: '分',
    et: 'Minut',
    lt: 'Minutę',
    ph: 'Minute',
    ch: '分',
  },
  Minutes: {
    en: 'Minutes',
    ja: '分',
    et: 'Minutit',
    lt: 'Minutes',
    ph: 'Minutes',
    ch: '分',
  },
  myAccount: {
    en: 'My Account',
    ja: 'マイアカウント',
    et: 'Minu konto',
    lt: 'Mano paskyra',
    ph: 'My Account',
    ch: '我的户口',
  },
  'My Bookings': {
    en: 'My Bookings',
    ja: '予約',
    et: 'Minu broneeringud',
    lt: 'Mano užsakymai',
    ph: 'My Bookings',
    ch: 'My Bookings',
  },
  'My Notifications': {
    en: 'My Notifications',
    ja: 'My Notifications', // Need translations
    et: 'My Notifications', // Need translations
    lt: 'My Notifications', // Need translations
    ph: 'My Notifications',
    ch: 'My Notifications',
  },
  'My Profile': {
    en: 'My Profile',
    ja: 'プロフィール',
    et: 'Minu profiil',
    lt: 'Mano paskyra',
    ph: 'My Profile',
    ch: 'My Profile',
  },
  newChallenge: {
    en: 'You have just completed the <1>{{name}}</1> challenge.',
    ja: 'You have just completed the <1>{{name}}</1> challenge.',
    et: 'You have just completed the <1>{{name}}</1> challenge.',
    lt: 'You have just completed the <1>{{name}}</1> challenge.',
    ph: 'You have just completed the <1>{{name}}</1> challenge.',
    ch: 'You have just completed the <1>{{name}}</1> challenge.',
  },
  Now: {
    en: 'Now',
    ja: '現在',
    et: 'Nüüd',
    lt: 'Tik ką',
    ph: 'Now',
    ch: '现在',
  },
  'Open App': {
    en: 'Open App',
    ja: 'Open App',
    et: 'Open App',
    lt: 'Open App',
    ph: 'Open App',
    ch: 'Open App',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    ja: 'プライバシーポリシー',
    et: 'Privaatsuspoliitika',
    lt: 'Privatumo politika',
    ph: 'Privacy Policy',
    ch: '隐私政策',
  },
  'Privacy Policy': {
    en: 'Privacy Policy',
    ja: 'プライバシーポリシー',
    et: 'Privaatsuspoliitika',
    lt: 'Privatumo politika',
    ph: 'Privacy Policy',
    ch: '隐私政策',
  },
  projects_one: {
    en: 'Project',
    ja: 'プロジェクト',
    et: 'Projekt',
    lt: 'Projektas',
    ph: 'Project',
    ch: 'Project',
  },
  projects_other: {
    en: 'Projects',
    ja: 'プロジェクト',
    et: 'Projektid',
    lt: 'Projektai',
    ph: 'Projects',
    ch: 'Projects',
  },
  Project: {
    en: 'Project',
    ja: 'プロジェクト',
    et: 'Projekt',
    lt: 'Projektas',
    ph: 'Project',
    ch: 'Project',
  },
  projectApproved: {
    en: 'Woohoo! Your project, <1>{{name}}</1>, has been approved!',
    ja: 'やった！あなたのプロジェクト <1>{{name}}</1>が承認されました！',
    et: 'Juhuu! Sinu projekt <1>{{name}}</1>, on kinnitatud!',
    lt: 'Sveikinam, tavo projektas <1>{{name}}</1> patvirtintas!',
    ph: 'Woohoo! Your project, <1>{{name}}</1>, has been approved!',
    ch: 'Woohoo! Your project, <1>{{name}}</1>, has been approved!',
  },
  Projects: {
    en: 'Projects',
    ja: 'プロジェクト',
    et: 'Projektid',
    lt: 'Projektai',
    ph: 'Projects',
    ch: 'Projects',
  },
  'Read less': {
    en: 'Read less',
    ja: 'もっと少なく',
    et: 'Loe vähem',
    lt: 'Matyti mažiau',
    ph: 'Read less',
    ch: 'Read less',
  },
  'Read more': {
    en: 'Read more',
    ja: 'もっと詳しく',
    et: 'Loe edasi',
    lt: 'Skaityti daugiau',
    ph: 'Read more',
    ch: 'Read more',
  },
  Rewards: {
    en: 'Rewards',
    ja: 'Rewards', // Need translation
    et: 'Rewards', // Need translation
    lt: 'Rewards', // Need translation
    ph: 'Rewards',
    ch: 'Rewards',
  },
  Second: {
    en: 'Second',
    ja: '秒',
    et: 'Sekund',
    lt: 'Sekundę',
    ph: 'Second',
    ch: '秒',
  },
  Seconds: {
    en: 'Seconds',
    ja: '秒',
    et: 'Sekundit',
    lt: 'Sekundes',
    ph: 'Seconds',
    ch: '秒',
  },
  January: {
    en: 'January',
    ja: '一月',
    et: 'Jaanuar',
    lt: 'Sausis',
    ph: 'January',
    ch: '1月',
  },
  February: {
    en: 'February',
    ja: '二月',
    et: 'Veebruar',
    lt: 'Vasaris',
    ph: 'February',
    ch: '2月',
  },
  March: {
    en: 'March',
    ja: '三月',
    et: 'Märts',
    lt: 'Kovas',
    ph: 'March',
    ch: '3月',
  },
  April: {
    en: 'April',
    ja: '４月',
    et: 'Aprill',
    lt: 'Balandis',
    ph: 'April',
    ch: '4月',
  },
  May: {
    en: 'May',
    ja: '５月',
    et: 'Mai',
    lt: 'Gergužė',
    ph: 'May',
    ch: '5月',
  },
  June: {
    en: 'June',
    ja: '６月',
    et: 'Juuni',
    lt: 'Birželis',
    ph: 'June',
    ch: '6月',
  },
  July: {
    en: 'July',
    ja: '７月',
    et: 'Juuli',
    lt: 'Liepa',
    ph: 'July',
    ch: '7月',
  },
  August: {
    en: 'August',
    ja: '８月',
    et: 'August',
    lt: 'Rugpjūtis',
    ph: 'August',
    ch: '8月',
  },
  September: {
    en: 'September',
    ja: '９月',
    et: 'September',
    lt: 'Rugsėjis',
    ph: 'September',
    ch: '9月',
  },
  October: {
    en: 'October',
    ja: '１０月',
    et: 'Oktoober',
    lt: 'Spalis',
    ph: 'October',
    ch: '10月',
  },
  November: {
    en: 'November',
    ja: '１１月',
    et: 'November',
    lt: 'Lapkritis',
    ph: 'November',
    ch: '11月',
  },
  December: {
    en: 'December',
    ja: '１２月',
    et: 'Detsember',
    lt: 'Gruodis',
    ph: 'December',
    ch: '12月',
  },
  sentMessage: {
    en: 'Message sent successfully',
    ja: 'Message sent successfully',
    et: 'Message sent successfully',
    lt: 'Message sent successfully',
    ph: 'Message sent successfully',
    ch: 'Message sent successfully',
  },
  sentMessageTo: {
    en: 'Sent a message to {{name}}',
    ja: '{{name}}にメッセージを送る',
    et: 'Sõnum saadetud adressaadile: {{name}}',
    lt: 'Išsiųsta žinutė vartotojui {{name}}',
    ph: 'Sent a message to {{name}}',
    ch: 'Sent a message to {{name}}',
  },
  Send: {
    en: 'Send',
    ja: '送信',
    et: 'Saada',
    lt: 'Išsiųsti',
    ph: 'Send',
    ch: 'Send',
  },
  Settings: {
    en: 'Settings',
    ja: '設定',
    et: 'Seaded',
    lt: 'Nustatymai',
    ph: 'Settings',
    ch: 'Settings',
  },
  starterCriteriaCompleted: {
    en: 'Congratulations! You are now a VIVINAUT! Welcome to the VIVITA Family!',
    ja: 'Congratulations! You are now a VIVINAUT! Welcome to the VIVITA Family!',
    et: 'Congratulations! You are now a VIVINAUT! Welcome to the VIVITA Family!',
    lt: 'Congratulations! You are now a VIVINAUT! Welcome to the VIVITA Family!',
    ph: 'Congratulations! You are now a VIVINAUT! Welcome to the VIVITA Family!',
    ch: 'Congratulations! You are now a VIVINAUT! Welcome to the VIVITA Family!',
  },
  terms: {
    en: 'Terms and Conditions',
    ja: '規約と条件',
    et: 'Kasutustingimused',
    lt: 'Sąlygos',
    ph: 'Terms and Conditions',
    ch: '条款和条件',
  },
  'Terms of Service': {
    en: 'Terms of Service',
    ja: '規約と条件',
    et: 'Kasutustingimused',
    lt: 'Paslaugų teikimo sąlygos',
    ph: 'Terms of Service',
    ch: '条款和条件',
  },
  'There are no upcoming workshops at the moment. Check back soon!': {
    en: 'There are no upcoming workshops at the moment. Check back soon!',
    ja: '公開中のイベントがありません。またチェックしてください！',
    et: 'Hetkel ei ole töötubasid plaanis. Vaata varsti uuesti.',
    lt: 'Šiuo metu nėra vyksiančių renginių. Patikrink vėl šiek tiek vėliau. ',
    ph: 'There are no upcoming workshops at the moment. Check back soon!',
    ch: 'There are no upcoming workshops at the moment. Check back soon!',
  },
  'Type anything here': {
    en: 'Type anything here',
    ja: 'ここにメッセージ',
    et: 'Kirjuta siia',
    lt: 'Parašyk kažką čia ',
    ph: 'Type anything here',
    ch: 'Type anything here',
  },
  'Upcoming Events': {
    en: 'Upcoming Events',
    ja: '今後のイベント',
    et: 'Tulevased sündmused',
    lt: 'Vyksiantys renginiai',
    ph: 'Upcoming Events',
    ch: 'Upcoming Events',
  },
  users: {
    en: 'Users',
    ja: 'ユーザー',
    et: 'Kasutajad',
    lt: 'Vartotojai',
    ph: 'Users',
    ch: '用户',
  },
  VIVICOIN: {
    en: 'VIVICOIN',
    ja: 'VIVICOIN',
    et: 'VIVICOIN',
    lt: 'VIVICOIN',
    ph: 'VIVICOIN',
    ch: 'VIVICOIN',
  },
  VIVINAUTS: {
    en: 'VIVINAUTS',
    ja: 'VIVINAUTS',
    et: 'VIVINAUDID',
    lt: 'VIVINAUTAI',
    ph: 'VIVINAUTS',
    ch: 'VIVINAUTS',
  },
  Members: {
    en: 'Members',
    ja: 'Members',
    et: 'Members',
    lt: 'Members',
    ph: 'Members',
    ch: 'Members',
  },
};

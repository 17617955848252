export default {
  'No Public Portfolio found': {
    en: 'No Public Portfolio found',
    ja: 'No Public Portfolio found',
    et: 'No Public Portfolio found',
    lt: 'No Public Portfolio found',
    ph: 'No Public Portfolio found',
    ch: 'No Public Portfolio found',
  },
  'About the page': {
    en: 'About the page',
    ja: 'About the page',
    et: 'About the page',
    lt: 'About the page',
    ph: 'About the page',
    ch: 'About the page',
  },
  About: {
    en: 'About',
    ja: 'About',
    et: 'About',
    lt: 'About',
    ph: 'About',
    ch: 'About',
  },
  Projects: {
    en: 'Projects',
    ja: 'Projects',
    et: 'Projects',
    lt: 'Projects',
    ph: 'Projects',
    ch: 'Projects',
  },
  Badges: {
    en: 'Badges',
    ja: 'Badges',
    et: 'Badges',
    lt: 'Badges',
    ph: 'Badges',
    ch: 'Badges',
  },
  Events: {
    en: 'Events',
    ja: 'Events',
    et: 'Events',
    lt: 'Events',
    ph: 'Events',
    ch: 'Events',
  },
  Challenges: {
    en: 'Challenges',
    ja: 'Challenges',
    et: 'Challenges',
    lt: 'Challenges',
    ph: 'Challenges',
    ch: 'Challenges',
  },
  Creators: {
    en: 'Creators',
    ja: 'Creators',
    et: 'Creators',
    lt: 'Creators',
    ph: 'Creators',
    ch: 'Creators',
  },
  'Show all badges': {
    en: 'Show all badges',
    ja: 'Show all badges',
    et: 'Show all badges',
    lt: 'Show all badges',
    ph: 'Show all badges',
    ch: 'Show all badges',
  },
  'Show all challenges': {
    en: 'Show all challenges',
    ja: 'Show all challenges',
    et: 'Show all challenges',
    lt: 'Show all challenges',
    ph: 'Show all challenges',
    ch: 'Show all challenges',
  },
  'Show all projects': {
    en: 'Show all projects',
    ja: 'Show all projects',
    et: 'Show all projects',
    lt: 'Show all projects',
    ph: 'Show all projects',
    ch: 'Show all projects',
  },
  'Show all events': {
    en: 'Show all events',
    ja: 'Show all events',
    et: 'Show all events',
    lt: 'Show all events',
    ph: 'Show all events',
    ch: 'Show all events',
  },
  'Show all creator portfolios': {
    en: 'Show all creator portfolios',
    ja: 'Show all creator portfolios',
    et: 'Show all creator portfolios',
    lt: 'Show all creator portfolios',
    ph: 'Show all creator portfolios',
    ch: 'Show all creator portfolios',
  },
  'Find Out More': {
    en: 'Find Out More',
    ja: 'Find Out More',
    et: 'Find Out More',
    lt: 'Find Out More',
    ph: 'Find Out More',
    ch: 'Find Out More',
  },
};

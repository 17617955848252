export default {
  'Change Profile Image': {
    en: 'Change Profile Image',
    ja: 'プロフィール画像を変更する',
    et: 'Muuda profiilipilti',
    lt: 'Pakeisti profilio nuotrauką',
    ph: 'Change Profile Image',
    ch: 'Change Profile Image',
  },
  'Change Background Image': {
    en: 'Change Background Image',
    ja: '背景画像を変更する',
    et: 'Muuda taustapilti',
    lt: 'Pakeisti fono nuotrauką',
    ph: 'Change Background Image',
    ch: 'Change Background Image',
  },
  Save: {
    en: 'Save',
    ja: '保存',
    et: 'Salvesta',
    lt: 'Išsaugoti',
    ph: 'Save',
    ch: 'Save',
  },
  'Upload photo or drag a photo here': {
    en: 'Upload photo or drag a photo here',
    ja: '写真をアップロードするか、またはここへドロップする',
    et: 'Laadi üles või lohista pilt siia',
    lt: 'Įkelk nuotrauką arba atitempk ją čia',
    ph: 'Upload photo or drag a photo here',
    ch: 'Upload photo or drag a photo here',
  },
  Name: {
    en: 'Name',
    ja: '名',
    et: 'Eesnimi',
    lt: 'Vardas',
    ph: 'Name',
    ch: 'Name',
  },
  'Family Name': {
    en: 'Family Name',
    ja: '姓',
    et: 'Perekonnanimi',
    lt: 'Pavardė',
    ph: 'Family Name',
    ch: 'Family Name',
  },
  Username: {
    en: 'Username',
    ja: 'ユーザ名',
    et: 'Kasutajanimi',
    lt: 'vartotojo vardas',
    ph: 'Username',
    ch: 'Username',
  },
  Notifications: {
    en: 'Notifications',
    ja: '通知',
    et: 'Teavitused',
    lt: 'Pranešimai',
    ph: 'Notifications',
    ch: 'Notifications',
  },
  'Enable Notifications': {
    en: 'Enable Notifications',
    ja: '通知 ON',
    et: 'Luba teavitused',
    lt: 'Įjungti pranešimus',
    ph: 'Enable Notifications',
    ch: 'Enable Notifications',
  },
  'Badge Awards': {
    en: 'Badge Awards',
    ja: 'バッジ獲得',
    et: 'Märkide väljaandmine',
    lt: 'Ženkliukų apdovanojimai',
    ph: 'Badge Awards',
    ch: 'Badge Awards',
  },
  'Project Approval/Rejection': {
    en: 'Project Approval/Rejection',
    ja: 'プロジェクトの承認',
    et: 'Projekti heakskiit / tagasilükkamine',
    lt: 'Projekto patvirtinimas/atšaukimas',
    ph: 'Project Approval/Rejection',
    ch: 'Project Approval/Rejection',
  },
  'Project Likes': {
    en: 'Project Likes',
    ja: 'プロジェクトへのいいね',
    et: 'Meeldimised projektile',
    lt: 'Patiktukai ',
    ph: 'Project Likes',
    ch: 'Project Likes',
  },
  'Comment Replies': {
    en: 'Comment Replies',
    ja: 'コメントへの返信',
    et: 'Vastused kommentaarile',
    lt: 'Atsakymai į komentarus',
    ph: 'Comment Replies',
    ch: 'Comment Replies',
  },
  'Comment Likes': {
    en: 'Comment Likes',
    ja: 'コメントへのいいね',
    et: 'Kommentaar meeldib',
    lt: 'Komentarų patiktukai',
    ph: 'Comment Likes',
    ch: 'Comment Likes',
  },
  Email: {
    en: 'Email',
    ja: 'メールアドレス',
    et: 'E-mailiaadress',
    lt: 'Emailas',
    ph: 'Email',
    ch: 'Email',
  },
  Password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Salasõna',
    lt: 'Slaptažodis',
    ph: 'Password',
    ch: 'Password',
  },
  success: {
    en: 'Success!',
    ja: '完了！',
    et: 'Õnnestus!',
    lt: 'Pavyko!',
    ph: 'Success!',
    ch: 'Success!',
  },
  'Password Changed': {
    en: 'Password Changed',
    ja: 'パスワード変更しました',
    et: 'Salasõna muudetud',
    lt: 'Slaptažodis pakeistas',
    ph: 'Password Changed',
    ch: 'Password Changed',
  },
  aboutMe: {
    en: 'About Me',
    ja: '個人情報',
    et: 'Minust',
    lt: 'Apie mane',
    ph: 'About Me',
    ch: 'About Me',
  },
  'Change Your Email': {
    en: 'Change Your Email',
    ja: 'メールアドレスを変更',
    et: 'Muuda salasõna',
    lt: 'Pakeisti emailą',
    ph: 'Change Your Email',
    ch: 'Change Your Email',
  },
  'Current Password': {
    en: 'Current Password',
    ja: '現在のパスワード',
    et: 'Praegune salasõna',
    lt: 'Dabartinis slaptažodis',
    ph: 'Current Password',
    ch: 'Current Password',
  },
  'New Password': {
    en: 'New Password',
    ja: '新しいパスワード',
    et: 'Uus salasõna',
    lt: 'Naujas slaptažodis',
    ph: 'New Password',
    ch: 'New Password',
  },
  'New Email': {
    en: 'New Email',
    ja: '新しいメールアドレス',
    et: 'Uus e-mailiaadress',
    lt: 'Naujas emailas',
    ph: 'New Email',
    ch: 'New Email',
  },
  'Set New Email': {
    en: 'Set New Email',
    ja: '新しいメールアドレスを設定',
    et: 'Seadista uus e-mailiaadress',
    lt: 'Nustatyti naują emailą',
    ph: 'Set New Email',
    ch: 'Set New Email',
  },
  'Set New Password': {
    en: 'Set New Password',
    ja: '新しいパスワードを設定',
    et: 'Seadista uus salasõna',
    lt: 'Nustatyti naują slaptažodį',
    ph: 'Set New Password',
    ch: 'Set New Password',
  },
  'Profile Saved!': {
    en: 'Profile Saved!',
    ja: 'プロフィールを保存しました',
    et: 'Profiil salvestatud!',
    lt: 'Paskyra išsaugota!',
    ph: 'Profile Saved!',
    ch: 'Profile Saved!',
  },
  myAccount: {
    en: 'My Account',
    ja: 'マイアカウント',
    et: 'Minu konto',
    lt: 'Mano paskyra',
    ph: 'My Account',
    ch: '我的户口',
  },
  profile: {
    en: 'Profile',
    ja: 'プロフィール',
    et: 'Profiil',
    lt: 'Paskyra',
    ph: 'Profile',
    ch: '户口资料',
  },
  username: {
    en: 'Username',
    ja: 'ユーザー名',
    et: 'Kasutajanimi',
    lt: 'Vartotojo vardas',
    ph: 'Username',
    ch: '用户名称',
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-mailiaadress',
    lt: 'Emailas',
    ph: 'Email',
    ch: '电邮地址',
  },
  password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Salasõna',
    lt: 'Slaptažodis',
    ph: 'Password',
    ch: '密码',
  },
  changeUsername: {
    en: 'Change your Username',
    ja: 'ユーザー名変更',
    et: 'Muuda oma kasutajanime',
    lt: 'Pakeisti vartotojo vardą',
    ph: 'Change your Username',
    ch: '更换用户名称',
  },
  changeEmail: {
    en: 'Change your Email',
    ja: 'メールアドレス変更',
    et: 'Muuda salasõna',
    lt: 'Pakeisti emailą',
    ph: 'Change your Email',
    ch: '更换电邮地址',
  },
  changePassword: {
    en: 'Set Password',
    ja: 'パスワードを設定',
    et: 'Määra parool',
    lt: 'Nustatyti slaptažodį',
    ph: 'Set Password',
    ch: '设置密码',
  },
  newUsername: {
    en: 'New Username',
    ja: '新しいユーザー名',
    et: 'Uus kasutajanimi',
    lt: 'Naujas vartotojo vardas',
    ph: 'New Username',
    ch: '新用户名称',
  },
  newEmail: {
    en: 'New Email',
    ja: '新しいメールアドレス',
    et: 'Uus e-mailiaadress',
    lt: 'Naujas emailas',
    ph: 'New Email',
    ch: '新电邮地址',
  },
  newPassword: {
    en: 'New Password',
    ja: '新しいパスワード',
    et: 'Seadista uus salasõna',
    lt: 'Naujas slaptažodis',
    ph: 'New Password',
    ch: '新密码',
  },
  setNewUsername: {
    en: 'Set New Username',
    ja: '新しいユーザー名を設定',
    et: 'Määra uus kasutajanimi',
    lt: 'Nustatyti naują vartotojo vardą',
    ph: 'Set New Username',
    ch: '设置新用户名称',
  },
  setNewEmail: {
    en: 'Set New Email',
    ja: '新しいメールアドレスを設定',
    et: 'Seadista uus e-mailiaadress',
    lt: 'Nustatyti naują emailą',
    ph: 'Set New Email',
    ch: '设置新电邮地址',
  },
  setNewPassword: {
    en: 'Set New Password',
    ja: '新しいパスワードを設定',
    et: 'Määra uus parool',
    lt: 'Nustatyti naują slaptažodį',
    ph: 'Set New Password',
    ch: '设置新密码',
  },
  currentPassword: {
    en: 'Current Password',
    ja: '現在のパスワード',
    et: 'Praegune salasõna',
    lt: 'Dabartinis slaptažodis',
    ph: 'Current Password',
    ch: '当前的密码',
  },
  forgotPassword: {
    en: 'Forgot?',
    ja: '忘れた？',
    et: 'Unustasid parooli?',
    lt: 'Pamiršai?',
    ph: 'Forgot?',
    ch: '忘了密码？',
  },
  successUsername: {
    en: 'Username Changed',
    ja: 'ユーザー名を変更しました。',
    et: 'Kasutajanimi muudetud',
    lt: 'Vartotojo vardas pakeistas',
    ph: 'Username Changed',
    ch: '用户名称已更改',
  },
  successEmail: {
    en: 'An email has been sent to {{email}}, please check it and proceed from there.',
    ja: '{{email}}にメールを送信しました。ご確認ください。',
    et: 'E-mail on saadetud aadressile {{email}}, palun kontrolli ja jätka sealt',
    lt: 'Emailą išsiuntėme į {{email}}, pasitikrink - nurodymus rasi ten. ',
    ph: 'An email has been sent to {{email}}, please check it and proceed from there.',
    ch: '已将电子邮件发送到 {{email}}。 请查看并按照电邮内的指示履行。',
  },
  successPassword: {
    en: 'Password Changed',
    ja: 'パスワードを変更しました。',
    et: 'Parool muudetud',
    lt: 'Slaptažodis pakeistas',
    ph: 'Password Changed',
    ch: '密码已更换',
  },
  linkSnsAccountsHeader: {
    en: 'Link SNS Accounts',
    ja: 'SNSアカウントをリンクする',
    et: 'Lingi SNS kontod',
    lt: 'Prijungti SNS paskyras',
    ph: 'Link SNS Accounts',
    ch: '链接SNS户口',
  },
  linkSnsAccountsText: {
    en: 'Link your social media accounts to easily log in',
    ja: 'SNSアカウントをリンクすればログインがやりやすくなれます。',
    et: 'Lingi oma sotsiaalmeedia kontod, et lihtsasti sisse logida',
    lt: 'Prijunk savo socialinius kanalus, kad lengviau prisijungtum prie tinklapio',
    ph: 'Link your social media accounts to easily log in',
    ch: '链接社交媒体帐户以轻松登录',
  },
  dangerZone: {
    en: 'Danger Zone',
    ja: '危険ゾーン',
    et: 'Ohutsoon',
    lt: 'Pavojus',
    ph: 'Danger Zone',
    ch: 'Danger Zone',
  },
  deleteAccount: {
    en: 'Delete Account',
    ja: 'アカウント削除',
    et: 'Kustuta konto',
    lt: 'Ištrinti paskyrą',
    ph: 'Delete Account',
    ch: '危险区',
  },
  deleteAccountWarning: {
    en: 'This action is unreversible. Please be certain.',
    ja: 'このアクションは元に戻すことができません。 確認してください。',
    et: 'See tegevus on lõplik, kas oled kindel?',
    lt: 'Šis veiksmas - negrįžtamas. Pasitikrink, ar tikrai nesuklydai. ',
    ph: 'This action is unreversible. Please be certain.',
    ch: '此操作不可逆。请确定。',
  },
  deleteAccountText: {
    en: 'To proceed with deleting this account. Please type in your username, email and password for the account.',
    ja: 'このアカウントの削除を続行するために、アカウントのユーザー名、メールアドレス、パスワードを入力してください。',
    et: 'Konto kustutamiseks sisesta palun oma kasutajanimi, email ja parool.',
    lt: 'Kad ištrintum savo paskyrą, įrašyk savo vartotojo vardą, emailą ir slaptažodį.',
    ph: 'To proceed with deleting this account. Please type in your username, email and password for the account.',
    ch: '继续删除此帐户。请键入用户名、电子邮件和密码',
  },
  deleteAccountForever: {
    en: 'Delete Account Forever',
    ja: '永久にアカウントを削除',
    et: 'Kustuta konto lõplikult',
    lt: 'Ištrinti paskyrą visam laikui, negrįžtamai.',
    ph: 'Delete Account Forever',
    ch: '永远删除用户',
  },
  accountType: {
    en: 'Account Type',
    ja: 'アカウントの種類',
    et: 'Konto tüüp',
    lt: 'Paskyros tipas',
    ph: 'Account Type',
    ch: '用户类型',
  },
  upgrade: {
    en: 'Upgrade',
    ja: 'アップグレードする',
    et: 'Uuenda',
    lt: 'Pagerinti',
    ph: 'Upgrade',
    ch: '升级',
  },
  secondaryEmail: {
    en: 'Secondary Email',
    ja: 'Secondary Email',
    et: 'Secondary Email',
    lt: 'Secondary Email',
    ph: 'Secondary Email',
    ch: 'Secondary Email',
  },
  'changeSecondary Email': {
    en: 'Change Secondary Email',
    ja: 'Change Secondary Email',
    et: 'Change Secondary Email',
    lt: 'Change Secondary Email',
    ph: 'Change Secondary Email',
    ch: 'Change Secondary Email',
  },
  'newSecondary Email': {
    en: 'New Secondary Email',
    ja: 'New Secondary Email',
    et: 'New Secondary Email',
    lt: 'New Secondary Email',
    ph: 'New Secondary Email',
    ch: 'New Secondary Email',
  },
  'setNewSecondary Email': {
    en: 'Set New Secondary Email',
    ja: 'Set New Secondary Email',
    et: 'Set New Secondary Email',
    lt: 'Set New Secondary Email',
    ph: 'Set New Secondary Email',
    ch: 'Set New Secondary Email',
  },
  successEmailTwo: {
    en: 'Saved Secondary Email',
    ja: 'Saved Secondary Email',
    et: 'Saved Secondary Email',
    lt: 'Saved Secondary Email',
    ph: 'Saved Secondary Email',
    ch: 'Saved Secondary Email',
  },
  'Select Project': {
    en: 'Select Project',
    ja: 'Select Project',
    et: 'Select Project',
    lt: 'Select Project',
    ph: 'Select Project',
    ch: 'Select Project',
  },
  'No other projects can be selected': {
    en: 'No other projects can be selected',
    ja: 'No other projects can be selected',
    et: 'No other projects can be selected',
    lt: 'No other projects can be selected',
    ph: 'No other projects can be selected',
    ch: 'No other projects can be selected',
  },
  'Remove Project': {
    en: 'Remove Project',
    ja: 'Remove Project',
    et: 'Remove Project',
    lt: 'Remove Project',
    ph: 'Remove Project',
    ch: 'Remove Project',
  },
  'No projects have been selected by this member yet': {
    en: 'No projects have been selected by this member yet',
    ja: 'No projects have been selected by this member yet',
    et: 'No projects have been selected by this member yet',
    lt: 'No projects have been selected by this member yet',
    ph: 'No projects have been selected by this member yet',
    ch: 'No projects have been selected by this member yet',
  },
  'Selected Projects': {
    en: 'Selected Projects',
    ja: 'Selected Projects',
    et: 'Selected Projects',
    lt: 'Selected Projects',
    ph: 'Selected Projects',
    ch: 'Selected Projects',
  },
  'View Public Portfolio': {
    en: 'View Public Portfolio',
    ja: 'View Public Portfolio',
    et: 'View Public Portfolio',
    lt: 'View Public Portfolio',
    ph: 'View Public Portfolio',
    ch: 'View Public Portfolio',
  },
  'Select and reorder projects to put in your portfolio!': {
    en: 'Select and reorder projects to put in your portfolio!',
    ja: 'Select and reorder projects to put in your portfolio!',
    et: 'Select and reorder projects to put in your portfolio!',
    lt: 'Select and reorder projects to put in your portfolio!',
    ph: 'Select and reorder projects to put in your portfolio!',
    ch: 'Select and reorder projects to put in your portfolio!',
  },
  'Edit Portfolio': {
    en: 'Edit Portfolio',
    ja: 'Edit Portfolio',
    et: 'Edit Portfolio',
    lt: 'Edit Portfolio',
    ph: 'Edit Portfolio',
    ch: 'Edit Portfolio',
  },
  'All Projects': {
    en: 'All Projects',
    ja: 'All Projects',
    et: 'All Projects',
    lt: 'All Projects',
    ph: 'All Projects',
    ch: 'All Projects',
  },
  'About Me': {
    en: 'About Me',
    ja: 'About Me',
    et: 'About Me',
    lt: 'About Me',
    ph: 'About Me',
    ch: 'About Me',
  },
  'My Projects': {
    en: 'My Projects',
    ja: 'My Projects',
    et: 'My Projects',
    lt: 'My Projects',
    ph: 'My Projects',
    ch: 'My Projects',
  },
  'No projects listed': {
    en: 'No projects listed',
    ja: 'No projects listed',
    et: 'No projects listed',
    lt: 'No projects listed',
    ph: 'No projects listed',
    ch: 'No projects listed',
  },
  'View more projects': {
    en: 'View more projects',
    ja: 'View more projects',
    et: 'View more projects',
    lt: 'View more projects',
    ph: 'View more projects',
    ch: 'View more projects',
  },
  'No Public Portfolio found': {
    en: 'No Public Portfolio found',
    ja: 'No Public Portfolio found',
    et: 'No Public Portfolio found',
    lt: 'No Public Portfolio found',
    ph: 'No Public Portfolio found',
    ch: 'No Public Portfolio found',
  },
  'Drag and drop the projects to change the order they appear on your public portfolio page':
    {
      en: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      ja: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      et: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      lt: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      ph: 'Drag and drop the projects to change the order they appear on your public portfolio page',
      ch: 'Drag and drop the projects to change the order they appear on your public portfolio page',
    },
  'Inspired By': {
    en: 'Inspired By',
    ja: 'Inspired By',
    et: 'Inspired By',
    lt: 'Inspired By',
    ph: 'Inspired By',
    ch: 'Inspired By',
  },
  'Created By': {
    en: 'Created By',
    ja: 'Created By',
    et: 'Created By',
    lt: 'Created By',
    ph: 'Created By',
    ch: 'Created By',
  },
  'Selected! Scroll up to rearrange / remove project!': {
    en: 'Selected! Scroll up to rearrange / remove project!',
    ja: 'Selected! Scroll up to rearrange / remove project!',
    et: 'Selected! Scroll up to rearrange / remove project!',
    lt: 'Selected! Scroll up to rearrange / remove project!',
    ph: 'Selected! Scroll up to rearrange / remove project!',
    ch: 'Selected! Scroll up to rearrange / remove project!',
  },
  'You do not have any projects listed': {
    en: 'You do not have any projects listed',
    ja: 'You do not have any projects listed',
    et: 'You do not have any projects listed',
    lt: 'You do not have any projects listed',
    ph: 'You do not have any projects listed',
    ch: 'You do not have any projects listed',
  },
  'Profile picture or cover photo missing!': {
    en: 'Profile picture or cover photo missing!',
    ja: 'Profile picture or cover photo missing!',
    et: 'Profile picture or cover photo missing!',
    lt: 'Profile picture or cover photo missing!',
    ph: 'Profile picture or cover photo missing!',
    ch: 'Profile picture or cover photo missing!',
  },
  'Edit Profile': {
    en: 'Edit Profile',
    ja: 'Edit Profile',
    et: 'Edit Profile',
    lt: 'Edit Profile',
    ph: 'Edit Profile',
    ch: 'Edit Profile',
  },
  'Your portfolio seems to be a little incomplete! You can add in a profile picture or cover photo by clicking the button below!': {
    en: 'Your portfolio seems to be a little incomplete! You can add in a profile picture or cover photo by clicking the button below!',
    ja: 'Your portfolio seems to be a little incomplete! You can add in a profile picture or cover photo by clicking the button below!',
    et: 'Your portfolio seems to be a little incomplete! You can add in a profile picture or cover photo by clicking the button below!',
    lt: 'Your portfolio seems to be a little incomplete! You can add in a profile picture or cover photo by clicking the button below!',
    ph: 'Your portfolio seems to be a little incomplete! You can add in a profile picture or cover photo by clicking the button below!',
    ch: 'Your portfolio seems to be a little incomplete! You can add in a profile picture or cover photo by clicking the button below!',
  },
  and: {
    en: 'and',
    ja: 'and',
    et: 'and',
    lt: 'and',
    ph: 'and',
    ch: 'and',
  },
};

import React from 'react';
// eslint-disable-next-line import/prefer-default-export
export function SearchIcon({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill={color || '#fff'}
        fillOpacity="0.66"
        fillRule="evenodd"
        d="M2.7 6.95a4.25 4.25 0 117.306 2.954 1.001 1.001 0 00-.102.102A4.25 4.25 0 012.7 6.95zm7.906 5.07a6.25 6.25 0 111.414-1.414l2.987 2.987a1 1 0 11-1.414 1.414l-2.987-2.987z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export const EventQuestionType = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  TEXT: 'TEXT',
};

export const eventQuestionTypes = [
  EventQuestionType.SINGLE,
  EventQuestionType.MULTIPLE,
  EventQuestionType.TEXT,
];
export default {
  Bookings: {
    en: 'Bookings',
    ja: '予約',
    et: 'Broneeringud',
    lt: 'Registracijos',
    ph: 'Bookings',
    ch: 'Bookings',
  },
  'Book a visit to VIVISTOP!': {
    en: 'Book a visit to VIVISTOP!',
    ja: 'VIVISTOPのセッションを予約する',
    et: 'Broneeri VIVIVSTOPi külastus',
    lt: 'Registruokis į vizitą į VIVISTOPą!',
    ph: 'Book a visit to VIVISTOP!',
    ch: 'Book a visit to VIVISTOP!',
  },
  'Register for upcoming workshops and events!': {
    en: 'Register for upcoming workshops and events!',
    ja: '今後のイベントに登録してください！',
    et: 'Registreeru tulevastele töötubadele ja üritustele!',
    lt: 'Registruokitės į būsimus seminarus ir renginius!',
    ph: 'Register for upcoming workshops and events!',
    ch: '报名参加即将举行的活动！',
  },
  'View My Bookings': {
    en: 'View My Bookings',
    ja: '予約一覧',
    et: 'Halda broneeringuid',
    lt: 'Peržiūrėti mano registracijas',
    ph: 'View My Bookings',
    ch: 'View My Bookings',
  },
  'Booking Calendar': {
    en: 'Booking Calendar',
    ja: '予約カレンダー',
    et: 'Broneeringute kalender',
    lt: 'Registracijų kalendorius',
    ph: 'Booking Calendar',
    ch: 'Booking Calendar',
  },
  'Events and Workshops': {
    en: 'Events and Workshops',
    ja: 'イベントとワークショップ',
    et: 'Sündmused ja töötoad',
    lt: 'Renginiai ir dirbtuvės',
    ph: 'Events and Workshops',
    ch: 'Events and Workshops',
  },
  'Please cancel as at least 48 hours prior to your visit to enable other members to have a chance to take up the slot. Thank you!':
    {
      en: 'Please cancel as at least 48 hours prior to your visit to enable other members to have a chance to take up the slot. Thank you!',
      ja: 'Please cancel as at least 48 hours prior to your visit to enable other members to have a chance to take up the slot. Thank you!',
      et: 'Palun tühista registreering hiljemalt 48 tundi enne plaanitud külastust, et keegi teine saaks sinu asemel tulla. Aitäh!',
      lt: 'Prašome registraciją atšaukti likus ne mažiau nei 48 valandoms iki tavo vizito, kad kažkas kitas spėtų užimti tavo vietą. Ačiū!',
      ph: 'Please cancel as at least 48 hours prior to your visit to enable other members to have a chance to take up the slot. Thank you!',
      ch: 'Please cancel as at least 48 hours prior to your visit to enable other members to have a chance to take up the slot. Thank you!',
    },
  'Yay you are registered for a workshop!': {
    en: 'Yay you are registered for a workshop!',
    ja: 'Yay you are registered for a workshop!',
    et: 'Hurraa! Oled registreeritud töötuppa.',
    lt: 'Valio, užsiregistravai į dirbtuves!',
    ph: 'Yay you are registered for a workshop!',
    ch: 'Yay you are registered for a workshop!',
  },
  'Yay you booked a session!': {
    en: 'Yay you booked a session!',
    ja: 'Yay you booked a session!',
    et: 'Hurraa! Oled registreeritud vabategevusse.',
    lt: 'Valio, užsiregistravai į vizitą. ',
    ph: 'Yay you booked a session!',
    ch: 'Yay you booked a session!',
  },
  'Thank you for registering, please await confirmation': {
    en: 'Thank you for registering, please await confirmation',
    ja: 'Thank you for registering, please await confirmation',
    et: 'Aitäh registreerumast! Oota, kuni saadame sulle kinnituse.',
    lt: 'Ačiū už registraciją, lauk patvirtinimo. ',
    ph: 'Thank you for registering, please await confirmation',
    ch: 'Thank you for registering, please await confirmation',
  },
  Register: {
    en: 'Register',
    ja: 'Register',
    et: 'Registreeri',
    lt: 'Registruotis',
    ph: 'Register',
    ch: 'Register',
  },
  'Book Now': {
    en: 'Book Now',
    ja: 'Book Now',
    et: 'Registreeri nüüd',
    lt: 'Registruotis dabar',
    ph: 'Book Now',
    ch: 'Book Now',
  },
  'Free Flow Session': {
    en: 'Free Flow Session',
    ja: 'Free Flow Session',
    et: 'Vabategevus',
    lt: 'Laisvos kūrybos laikas',
    ph: 'Free Flow Session',
    ch: 'Free Flow Session',
  },
  Duration: {
    en: 'Duration',
    ja: 'Duration',
    et: 'Kestus',
    lt: 'Trukmė',
    ph: 'Duration',
    ch: 'Duration',
  },
  hours: {
    en: 'hours',
    ja: 'hours',
    et: 'tundi',
    lt: 'valandos',
    ph: 'hours',
    ch: 'hours',
  },
  'You have already booked this session': {
    en: 'You have already booked this session',
    ja: 'You have already booked this session',
    et: 'Oled juba registreerinud sellele sessioonile',
    lt: 'Jau užsiregistravai į šią veiklą',
    ph: 'You have already booked this session',
    ch: 'You have already booked this session',
  },
  'Sorry, you have booked another session for this day': {
    en: 'Sorry, you have booked another session for this day',
    ja: 'Sorry, you have booked another session for this day',
    et: 'Vabandust! Oled registreeritud teisele sessioonile samal päeval.',
    lt: 'Atleisk, bet jau užsiregistravai šiai dienai',
    ph: 'Sorry, you have booked another session for this day',
    ch: 'Sorry, you have booked another session for this day',
  },
  'Your registration is now pending': {
    en: 'Your registration is now pending',
    ja: 'Your registration is now pending',
    et: 'Sinu registreering ootab kinnitust.',
    lt: 'Tavo registracija laukia patvirtinimo',
    ph: 'Your registration is now pending',
    ch: 'Your registration is now pending',
  },
  'Sorry, you have reached the booking quota': {
    en: 'Sorry, you have reached the booking quota',
    ja: 'Sorry, you have reached the booking quota',
    et: 'Vabandust! Oled ületanud registreeringute piiri.',
    lt: 'Deja, pasiekei registracijų limitą',
    ph: 'Sorry, you have reached the booking quota',
    ch: 'Sorry, you have reached the booking quota',
  },
  'Sorry, this session is full': {
    en: 'Sorry, this session is full',
    ja: 'Sorry, this session is full',
    et: 'Vabandust! Sellele üritusele pole enam vabu kohti.',
    lt: 'Deja, šiuo laiku nebėra vietų',
    ph: 'Sorry, this session is full',
    ch: 'Sorry, this session is full',
  },
  Availability: {
    en: 'Availability',
    ja: 'Availability',
    et: 'Saadavus',
    lt: 'Laisvos vietos',
    ph: 'Availability',
    ch: 'Availability',
  },
  'Slots left': {
    en: 'Slots left',
    ja: 'Slots left',
    et: 'Vabad kohad',
    lt: 'Likusios vietos',
    ph: 'Slots left',
    ch: 'Slots left',
  },
  'Event description': {
    en: 'Event description',
    ja: 'Event description',
    et: 'Sündmuse kirjeldus',
    lt: 'Renginio apibūdinimas',
    ph: 'Event description',
    ch: 'Event description',
  },
  'If you are unable to make it': {
    en: 'If you are unable to make it',
    ja: 'If you are unable to make it',
    et: 'Kui sul ei õnnestu osaleda',
    lt: 'Jei negalėsi atvykti',
    ph: 'If you are unable to make it',
    ch: 'If you are unable to make it',
  },
  Workshop: {
    en: 'Workshop',
    ja: 'Workshop',
    et: 'Töötuba',
    lt: 'Dirbtuvės',
    ph: 'Workshop',
    ch: 'Workshop',
  },
  hide: {
    en: 'hide',
    ja: 'hide',
    et: 'peida',
    lt: 'nerodyti',
    ph: 'hide',
    ch: 'hide',
  },
  show: {
    en: 'show',
    ja: 'show',
    et: 'näita',
    lt: 'rodyti',
    ph: 'show',
    ch: 'show',
  },
  cancelled: {
    en: 'cancelled',
    ja: 'cancelled',
    et: 'tühistatud',
    lt: 'atšaukta',
    ph: 'cancelled',
    ch: 'cancelled',
  },
  'late cancellation': {
    en: 'late cancellation',
    ja: 'late cancellation',
    et: 'tühistamine',
    lt: 'vėlyvas atšaukimas',
    ph: 'late cancellation',
    ch: 'late cancellation',
  },
  booked: {
    en: 'booked',
    ja: 'booked',
    et: 'broneeritud',
    lt: 'užregistruota',
    ph: 'booked',
    ch: 'booked',
  },
  unsuccessful: {
    en: 'unsuccessful',
    ja: 'unsuccessful',
    et: 'ebaõnnestunud',
    lt: 'nesėkminga',
    ph: 'unsuccessful',
    ch: 'unsuccessful',
  },
  'pending confirmation': {
    en: 'pending confirmation',
    ja: 'pending confirmation',
    et: 'kinnituse ootel',
    lt: 'laukia patvirtinimo',
    ph: 'pending confirmation',
    ch: 'pending confirmation',
  },
  'Show More': {
    en: 'Show More',
    ja: 'Show More',
    et: 'Näita veel',
    lt: 'Rodyti daugiau',
    ph: 'Show More',
    ch: 'Show More',
  },
  'Show Less': {
    en: 'Show Less',
    ja: 'Show Less',
    et: 'Näita vähem',
    lt: 'Rodyti mažiau',
    ph: 'Show Less',
    ch: 'Show Less',
  },
  'My Bookings': {
    en: 'My Bookings',
    ja: '予約',
    et: 'Minu broneeringud',
    lt: 'Mano registracijos',
    ph: 'My Bookings',
    ch: 'My Bookings',
  },
  'View your sessions!': {
    en: 'View your sessions!',
    ja: 'セッションを表示します。',
    et: 'Halda broneeringuid',
    lt: 'Peržiūrėti savo registracijas',
    ph: 'View your sessions!',
    ch: 'View your sessions!',
  },
  'Book Here!': {
    en: 'Book Here!',
    ja: 'ここで予約！',
    et: 'Registreeri siin!',
    lt: 'Užsiregistruok čia!',
    ph: 'Book Here!',
    ch: 'Book Here!',
  },
  'Are you sure you want to cancel your booking?': {
    en: 'Are you sure you want to cancel your booking?',
    ja: 'Are you sure you want to cancel your booking?',
    et: 'Kas oled kindel, et soovid tühistada broneeringu?',
    lt: 'Ar tikrai nori atšaukti savo registraciją?',
    ph: 'Are you sure you want to cancel your booking?',
    ch: 'Are you sure you want to cancel your booking?',
  },
  "Don't cancel": {
    en: "Don't cancel",
    ja: "Don't cancel",
    et: 'Ära tühista',
    lt: 'Neatšaukti',
    ph: "Don't cancel",
    ch: "Don't cancel",
  },
  'Cancel booking': {
    en: 'Cancel booking',
    ja: 'Cancel booking',
    et: 'Tühista broneering',
    lt: 'Atšaukti registraciją',
    ph: 'Cancel booking',
    ch: 'Cancel booking',
  },
  'Upcoming Bookings': {
    en: 'Upcoming Bookings',
    ja: 'Upcoming Bookings',
    et: 'Tulevased broneeringud',
    lt: 'Artėjančios registracijos',
    ph: 'Upcoming Bookings',
    ch: 'Upcoming Bookings',
  },
  'Past Bookings': {
    en: 'Past Bookings',
    ja: 'Past Bookings',
    et: 'Varasemad broneeringud',
    lt: 'Buvusios registracijos',
    ph: 'Past Bookings',
    ch: 'Past Bookings',
  },
  'You have successfully cancelled your booking': {
    en: 'You have successfully cancelled your booking',
    ja: 'You have successfully cancelled your booking',
    et: 'Broneering tühistatud',
    lt: 'Atšaukei savo registraciją',
    ph: 'You have successfully cancelled your booking',
    ch: 'You have successfully cancelled your booking',
  },
  'You do not have any upcoming bookings!': {
    en: 'You do not have any upcoming bookings!',
    ja: 'You do not have any upcoming bookings!',
    et: 'Tulevased broneeringud puuduvad',
    lt: 'Tu neturi artėjančių registracijų',
    ph: 'You do not have any upcoming bookings!',
    ch: 'You do not have any upcoming bookings!',
  },
  'You do not have any past bookings!': {
    en: 'You do not have any past bookings!',
    ja: 'You do not have any past bookings!',
    et: 'Varasemad broneeringud puuduvad',
    lt: 'Tu neturi buvusių registracijų',
    ph: 'You do not have any past bookings!',
    ch: 'You do not have any past bookings!',
  },
  'Show Past Bookings': {
    en: 'Show Past Bookings',
    ja: 'Show Past Bookings',
    et: 'Näita varasemaid broneeringuid',
    lt: 'Rodyti buvusias registracijas',
    ph: 'Show Past Bookings',
    ch: 'Show Past Bookings',
  },
  'Hide Past Bookings': {
    en: 'Hide Past Bookings',
    ja: 'Hide Past Bookings',
    et: 'Peida varasemad broneeringud',
    lt: 'Paslėpti buvusias registracijas',
    ph: 'Hide Past Bookings',
    ch: 'Hide Past Bookings',
  },
  'Select an option': {
    en: 'Select an option',
    ja: 'Select an option',
    et: 'Vali',
    lt: 'Pasirinkti variantą',
    ph: 'Select an option',
    ch: 'Select an option',
  },
  'your answer': {
    en: 'your answer',
    ja: 'your answer',
    et: 'sinu vastus',
    lt: 'tavo atsakymas',
    ph: 'your answer',
    ch: 'your answer',
  },
  Back: {
    en: 'Back',
    ja: 'Back',
    et: 'Tagasi',
    lt: 'Atgal',
    ph: 'Back',
    ch: 'Back',
  },
  Submit: {
    en: 'Submit',
    ja: 'Submit',
    et: 'Saada',
    lt: 'Pateikti',
    ph: 'Submit',
    ch: 'Submit',
  },
  'Workshops / Events': {
    en: 'Workshops / Events',
    ja: 'Workshops / Events',
    et: 'Töötoad / Sündmused',
    lt: 'Dirbtuvės / Renginiai',
    ph: 'Workshops / Events',
    ch: 'Workshops / Events',
  },
  'Free Flow Sessions': {
    en: 'Free Flow Sessions',
    ja: 'Free Flow Sessions',
    et: 'Vabategevus',
    lt: 'Laisvos kūrybos laikas',
    ph: 'Free Flow Sessions',
    ch: 'Free Flow Sessions',
  },
  'No sessions for this day': {
    en: 'No sessions for this day',
    ja: 'No sessions for this day',
    et: 'Täna ei toimu midagi',
    lt: 'Šiandien nėra laikų',
    ph: 'No sessions for this day',
    ch: 'No sessions for this day',
  },
  'Registered!': {
    en: 'Registered!',
    ja: 'Registered!',
    et: 'Registreeritud!',
    lt: 'Užregistruota!',
    ph: 'Registered!',
    ch: 'Registered!',
  },
  'Booked!': {
    en: 'Booked!',
    ja: 'Booked!',
    et: 'Broneeritud!',
    lt: 'Užregistravai!',
    ph: 'Booked!',
    ch: 'Booked!',
  },
  'Pending Confirmation': {
    en: 'Pending Confirmation',
    ja: 'Pending Confirmation',
    et: 'kinnituse ootel',
    lt: 'laukia patvirtinimo',
    ph: 'Pending Confirmation',
    ch: 'Pending Confirmation',
  },
  'Fully Booked': {
    en: 'Fully Booked',
    ja: 'Fully Booked',
    et: 'Väljamüüdud',
    lt: 'Nebėra laisvų vietų',
    ph: 'Fully Booked',
    ch: 'Fully Booked',
  },
  'Availability: Fully Booked': {
    en: 'Availability: Fully Booked',
    ja: 'Availability: Fully Booked',
    et: 'Saadavus: väljamüüdud',
    lt: 'Nėra vietų',
    ph: 'Availability: Fully Booked',
    ch: 'Availability: Fully Booked',
  },
  'Workshop name': {
    en: 'Workshop name',
    ja: 'Workshop name',
    et: 'Töötoa nimi',
    lt: 'Dirbtuvių pavadinimas',
    ph: 'Workshop name',
    ch: 'Workshop name',
  },
  slotsLeft_zero: {
    en: '{{count}} slot left',
    ja: '{{count}} slot left',
    et: '{{count}} kohta vaba',
    lt: 'liko {{count}} vietų',
    ph: '{{count}} slot left',
    ch: '{{count}} slot left',
  },
  slotsLeft_one: {
    en: '{{count}} slot left',
    ja: '{{count}} slot left',
    et: '{{count}} koht vaba',
    lt: 'liko {{count}} vieta',
    ph: '{{count}} slot left',
    ch: '{{count}} slot left',
  },
  slotsLeft_other: {
    en: '{{count}} slots left',
    ja: '{{count}} slots left',
    et: '{{count}} kohta vaba',
    lt: 'liko {{count}} vietų',
    ph: '{{count}} slots left',
    ch: '{{count}} slots left',
  },
  'Why is my booking limited?': {
    en: 'Why is my booking limited?',
    ja: 'Why is my booking limited?',
    et: 'Miks on minu broneering piiratud?',
    lt: 'Kodėl mano registracija ribojama?',
    ph: 'Why is my booking limited?',
    ch: 'Why is my booking limited?',
  },
  'To allow all members an opportunity to visit VIVISTOP, members are restricted to booking':
    {
      en: 'To allow all members an opportunity to visit VIVISTOP, members are restricted to booking',
      ja: 'To allow all members an opportunity to visit VIVISTOP, members are restricted to booking',
      et: 'Et võimaldada kõigil liikmetel külastada VIVISTOPi, saab iga liige broneerida üksnes',
      lt: 'Kad visi galėtų išbandyti VIVISTOPą, nariams leidžiamas tik tam tikras registracijų kiekis',
      ph: 'To allow all members an opportunity to visit VIVISTOP, members are restricted to booking',
      ch: 'To allow all members an opportunity to visit VIVISTOP, members are restricted to booking',
    },
  '1 session per day': {
    en: '1 session per day',
    ja: '1 session per day',
    et: '1 külastuse päevas',
    lt: 'Viena vieta per dieną',
    ph: '1 session per day',
    ch: '1 session per day',
  },
  weekdayQupta: {
    en: '{{weekdayQuota}} weekday session(s) a month',
    ja: '{{weekdayQuota}} weekday session(s) a month',
    et: '{{weekdayQuota}} nädalasisest külastust kuus',
    lt: '{{weekdayQuota}} vietos per mėnesį darbo dienomis',
    ph: '{{weekdayQuota}} weekday session(s) a month',
    ch: '{{weekdayQuota}} weekday session(s) a month',
  },
  weekendQuota: {
    en: '{{weekendQuota}} weekend session(s) a month',
    ja: '{{weekendQuota}} weekend session(s) a month',
    et: '{{weekendQuota}} nädalavahetuse külastust kuus',
    lt: '{{weekendQuota}} vietos per mėnesį savaitgaliais',
    ph: '{{weekendQuota}} weekend session(s) a month',
    ch: '{{weekendQuota}} weekend session(s) a month',
  },
  quotaMonth: {
    en: 'For the month of {{month}}',
    ja: 'For the month of {{month}}',
    et: 'Kehtib kuus: {{month}}',
    lt: '{{month}} mėnesiui',
    ph: 'For the month of {{month}}',
    ch: 'For the month of {{month}}',
  },
  'Weekday quota': {
    en: 'Weekday quota',
    ja: 'Weekday quota',
    et: 'Päevane kvoot',
    lt: 'Vietų kiekis darbo dienomis',
    ph: 'Weekday quota',
    ch: 'Weekday quota',
  },
  'Weekend quota': {
    en: 'Weekend quota',
    ja: 'Weekend quota',
    et: 'Nädalavahetuse kvoot',
    lt: 'Vietų kiekis savaitgaliais',
    ph: 'Weekend quota',
    ch: 'Weekend quota',
  },
  Unlimited: {
    en: 'Unlimited',
    ja: 'Unlimited',
    et: 'Piiramatu',
    lt: 'Neribota',
    ph: 'Unlimited',
    ch: 'Unlimited',
  },
  'Quota reached!': {
    en: 'Quota reached!',
    ja: 'Quota reached!',
    et: 'Kvoot saavutatud!',
    lt: 'Riba pasiekta',
    ph: 'Quota reached!',
    ch: 'Quota reached!',
  },
  sessionsLeft_zero: {
    en: '{{count}} session left',
    ja: '{{count}} session left',
    et: '{{count}} külastust alles',
    lt: 'Liko {{count}} vietų',
    ph: '{{count}} session left',
    ch: '{{count}} session left',
  },
  sessionsLeft_one: {
    en: '{{count}} session left',
    ja: '{{count}} session left',
    et: '{{count}} külastus alles',
    lt: 'Liko {{count}} vieta',
    ph: '{{count}} session left',
    ch: '{{count}} session left',
  },
  sessionsLeft_other: {
    en: '{{count}} sessions left',
    ja: '{{count}} sessions left',
    et: '{{count}} külastust alles',
    lt: 'Liko {{count}} vietų',
    ph: '{{count}} sessions left',
    ch: '{{count}} sessions left',
  },
  'Read more': {
    en: 'Read more',
    ja: 'もっと詳しく',
    et: 'Loe edasi',
    lt: 'Skaityti daugiau',
    ph: 'Read more',
    ch: 'Read more',
  },
  'There are no upcoming workshops at the moment. Check back soon!': {
    en: 'There are no upcoming workshops at the moment. Check back soon!',
    ja: '公開中のイベントがありません。またチェックしてください！',
    et: 'Hetkel ei ole töötubasid plaanis. Vaata varsti uuesti.',
    lt: 'Šiuo metu nėra vyksiančių renginių. Patikrink vėl šiek tiek vėliau. ',
    ph: 'There are no upcoming workshops at the moment. Check back soon!',
    ch: 'There are no upcoming workshops at the moment. Check back soon!',
  },
  'Login to Register': {
    en: 'Login to Register',
    ja: 'Login to Register',
    et: 'Login to Register',
    lt: 'Login to Register',
    ph: 'Login to Register',
    ch: 'Login to Register',
  },
  'Public Events': {
    en: 'Public Events',
    ja: 'Public Events',
    et: 'Public Events',
    lt: 'Public Events',
    ph: 'Public Events',
    ch: 'Public Events',
  },
  'Find out more about all our upcoming events and workshops!': {
    en: 'Find out more about all our upcoming events and workshops!',
    ja: 'Find out more about all our upcoming events and workshops!',
    et: 'Find out more about all our upcoming events and workshops!',
    lt: 'Find out more about all our upcoming events and workshops!',
    ph: 'Find out more about all our upcoming events and workshops!',
    ch: 'Find out more about all our upcoming events and workshops!',
  },
  Calendar: {
    en: 'Calendar',
    ja: 'Calendar',
    et: 'Calendar',
    lt: 'Calendar',
    ph: 'Calendar',
    ch: 'Calendar',
  },
  'Given name': {
    en: 'Given name',
    ja: 'Given name',
    et: 'Given name',
    lt: 'Given name',
    ph: 'Given name',
    ch: 'Given name',
  },
  'Family name': {
    en: 'Family name',
    ja: 'Family name',
    et: 'Family name',
    lt: 'Family name',
    ph: 'Family name',
    ch: 'Family name',
  },
  'Contact number': {
    en: 'Contact number',
    ja: 'Contact number',
    et: 'Contact number',
    lt: 'Contact number',
    ph: 'Contact number',
    ch: 'Contact number',
  },
  "Guardian's Contact number": {
    en: "Guardian's Contact number",
    ja: "Guardian's Contact number",
    et: "Guardian's Contact number",
    lt: "Guardian's Contact number",
    ph: "Guardian's Contact number",
    ch: "Guardian's Contact number",
  },
  Email: {
    en: 'Email',
    ja: 'Email',
    et: 'Email',
    lt: 'Email',
    ph: 'Email',
    ch: 'Email',
  },
  "Guardian's email": {
    en: "Guardian's email",
    ja: "Guardian's email",
    et: "Guardian's email",
    lt: "Guardian's email",
    ph: "Guardian's email",
    ch: "Guardian's email",
  },
  "Guardian's name": {
    en: "Guardian's name",
    ja: "Guardian's name",
    et: "Guardian's name",
    lt: "Guardian's name",
    ph: "Guardian's name",
    ch: "Guardian's name",
  },
  registerFor: {
    en: 'Register for {{name}}',
    ja: 'Register for {{name}}',
    et: 'Register for {{name}}',
    lt: 'Register for {{name}}',
    ph: 'Register for {{name}}',
    ch: 'Register for {{name}}',
  },
  'Back to public events': {
    en: 'Back to public events',
    ja: 'Back to public events',
    et: 'Back to public events',
    lt: 'Back to public events',
    ph: 'Back to public events',
    ch: 'Back to public events',
  },
  'Please cancel as at least 48 hours prior to your visit to enable others to have a chance to take up the slot. The link to cancel will be included in our email confirmation. Thank you!': {
    en: 'Please cancel as at least 48 hours prior to your visit to enable others to have a chance to take up the slot. The link to cancel will be included in our email confirmation. Thank you!',
    ja: 'Please cancel as at least 48 hours prior to your visit to enable others to have a chance to take up the slot. The link to cancel will be included in our email confirmation. Thank you!',
    et: 'Please cancel as at least 48 hours prior to your visit to enable others to have a chance to take up the slot. The link to cancel will be included in our email confirmation. Thank you!',
    lt: 'Please cancel as at least 48 hours prior to your visit to enable others to have a chance to take up the slot. The link to cancel will be included in our email confirmation. Thank you!',
    ph: 'Please cancel as at least 48 hours prior to your visit to enable others to have a chance to take up the slot. The link to cancel will be included in our email confirmation. Thank you!',
    ch: 'Please cancel as at least 48 hours prior to your visit to enable others to have a chance to take up the slot. The link to cancel will be included in our email confirmation. Thank you!',
  },
  'Event Fully Booked': {
    en: 'Event Fully Booked',
    ja: 'Event Fully Booked',
    et: 'Event Fully Booked',
    lt: 'Event Fully Booked',
    ph: 'Event Fully Booked',
    ch: 'Event Fully Booked',
  },
  Next: {
    en: 'Next',
    ja: '次へ',
    et: 'Järgmine',
    lt: 'Kitas',
    ph: 'Next',
    ch: 'Next',
  },
  'Cancel Booking': {
    en: 'Cancel Booking',
    ja: 'Cancel Booking',
    et: 'Tühista Broneering',
    lt: 'Atšaukti Registraciją',
    ph: 'Cancel Booking',
    ch: 'Cancel Booking',
  },
  'Event is over': {
    en: 'Event is over',
    ja: 'Event is over',
    et: 'Event is over',
    lt: 'Event is over',
    ph: 'Event is over',
    ch: 'Event is over',
  },
  'Event facilitators': {
    en: 'Event facilitators',
    ja: 'Event facilitators',
    et: 'Event facilitators',
    lt: 'Event facilitators',
    ph: 'Event facilitators',
    ch: 'Event facilitators',
  },
  crew: {
    en: 'Crew of the day',
    ja: 'Crew of the day',
    et: 'Crew of the day',
    lt: 'Crew of the day',
    ph: 'Crew of the day',
    ch: 'Crew of the day',
  },
  'Ask the facilitators anything about': {
    en: 'Ask the facilitators anything about',
    ja: 'Ask the facilitators anything about',
    et: 'Ask the facilitators anything about',
    lt: 'Ask the facilitators anything about',
    ph: 'Ask the facilitators anything about',
    ch: 'Ask the facilitators anything about',
  },
  'Ask the crew anything about': {
    en: 'Ask the crew anything about',
    ja: 'Ask the crew anything about',
    et: 'Ask the crew anything about',
    lt: 'Ask the crew anything about',
    ph: 'Ask the crew anything about',
    ch: 'Ask the crew anything about',
  },
  Crew: {
    en: 'Crew',
    ja: 'Crew',
    et: 'Crew',
    lt: 'Crew',
    ph: 'Crew',
    ch: 'Crew',
  },
  Facilitator: {
    en: 'Facilitator',
    ja: 'Facilitator',
    et: 'Facilitator',
    lt: 'Facilitator',
    ph: 'Facilitator',
    ch: 'Facilitator',
  },
  'Ask the facilitators of the day anything about': {
    en: 'Ask the facilitators of the day anything about',
    ja: 'Ask the facilitators of the day anything about',
    et: 'Ask the facilitators of the day anything about',
    lt: 'Ask the facilitators of the day anything about',
    ph: 'Ask the facilitators of the day anything about',
    ch: 'Ask the facilitators of the day anything about',
  },
  'Ask the crew of the day anything about': {
    en: 'Ask the crew of the day anything about',
    ja: 'Ask the crew of the day anything about',
    et: 'Ask the crew of the day anything about',
    lt: 'Ask the crew of the day anything about',
    ph: 'Ask the crew of the day anything about',
    ch: 'Ask the crew of the day anything about',
  },
};

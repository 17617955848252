export const ProjectOrderType = {
  LATEST: 'LATEST',
  OLDEST: 'OLDEST',
  RANDOM: 'RANDOM',
};

export const projectOrderTypes = [
  ProjectOrderType.LATEST,
  ProjectOrderType.OLDEST,
  ProjectOrderType.RANDOM,
];

export default {
  'About this challenge': {
    en: 'About this challenge',
    ja: 'About this challenge',
    et: 'About this challenge',
    lt: 'About this challenge',
    ph: 'About this challenge',
    ch: 'About this challenge',
  },
  'All Categories': {
    en: 'All Categories',
    ja: 'All Categories',
    et: 'All Categories',
    lt: 'All Categories',
    ph: 'All Categories',
    ch: 'All Categories',
  },
  'All Challenges': {
    en: 'All Challenges',
    ja: 'All Challenges',
    et: 'All Challenges',
    lt: 'All Challenges',
    ph: 'All Challenges',
    ch: 'All Challenges',
  },
  'Attempt Challenge': {
    en: 'Attempt Challenge',
    ja: 'Attempt Challenge',
    et: 'Attempt Challenge',
    lt: 'Attempt Challenge',
    ph: 'Attempt Challenge',
    ch: 'Attempt Challenge',
  },
  awardProject_one: {
    en: 'Awarded {{count}} Project',
    ja: '{{count}} 件のプロジェクトを受賞',
    et: 'Antud {{count}} projektis',
    lt: 'Apdovanotas {{count}} projektas',
    ph: 'Awarded {{count}} Project',
    ch: 'Awarded {{count}} Project',
  },
  awardProject_zero: {
    en: 'Awarded {{count}} Project',
    ja: '{{count}} 件のプロジェクトを受賞',
    et: 'Antud {{count}} projektis',
    lt: 'Apdovanotas {{count}} projektas',
    ph: 'Awarded {{count}} Project',
    ch: 'Awarded {{count}} Project',
  },
  awardProject_other: {
    en: 'Awarded {{count}} Projects',
    ja: '{{count}} 件のプロジェクトを受賞',
    et: 'Antud {{count}} projektidele',
    lt: 'Apdovanoti {{count}} projektai',
    ph: 'Awarded {{count}} Projects',
    ch: 'Awarded {{count}} Projects',
  },
  Categories: {
    en: 'Categories',
    ja: 'Categories',
    et: 'Categories',
    lt: 'Categories',
    ph: 'Categories',
    ch: 'Categories',
  },
  Challenges: {
    en: 'Challenges',
    ja: 'Challenges',
    et: 'Challenges',
    lt: 'Challenges',
    ph: 'Challenges',
    ch: 'Challenges',
  },
  'Clear Filter': {
    en: 'Clear Filter',
    ja: 'Clear Filter',
    et: 'Clear Filter',
    lt: 'Clear Filter',
    ph: 'Clear Filter',
    ch: 'Clear Filter',
  },
  'Commonly Asked Questions': {
    en: 'Commonly Asked Questions',
    ja: 'Commonly Asked Questions',
    et: 'Commonly Asked Questions',
    lt: 'Commonly Asked Questions',
    ph: 'Commonly Asked Questions',
    ch: 'Commonly Asked Questions',
  },
  'Check out the challenges you can earn once you become a VIVINAUT!': {
    en: 'Check out the challenges you can earn once you become a VIVINAUT!',
    ja: 'Check out the challenges you can earn once you become a VIVINAUT!',
    et: 'Check out the challenges you can earn once you become a VIVINAUT!',
    lt: 'Check out the challenges you can earn once you become a VIVINAUT!',
    ph: 'Check out the challenges you can earn once you become a VIVINAUT!',
    ch: 'Check out the challenges you can earn once you become a VIVINAUT!',
  },
  'Created By': {
    en: 'Created By',
    ja: 'Created By',
    et: 'Created By',
    lt: 'Created By',
    ph: 'Created By',
    ch: 'Created By',
  },
  day_one: {
    en: '{{count}} day ',
    ja: '{{count}} day ',
    et: '{{count}} day ',
    lt: '{{count}} day ',
    ph: '{{count}} day ',
    ch: '{{count}} day ',
  },
  day_other: {
    en: '{{count}} days ',
    ja: '{{count}} days ',
    et: '{{count}} days ',
    lt: '{{count}} days ',
    ph: '{{count}} days ',
    ch: '{{count}} days ',
  },
  'Difficulty Level': {
    en: 'Difficulty Level',
    ja: 'Difficulty Level',
    et: 'Difficulty Level',
    lt: 'Difficulty Level',
    ph: 'Difficulty Level',
    ch: 'Difficulty Level',
  },
  earnChallenge_one: {
    en: 'Completed by {{count}} VIVINAUT',
    ja: 'Completed by {{count}} VIVINAUT',
    et: 'Completed by {{count}} VIVINAUT',
    lt: 'Completed by {{count}} VIVINAUT',
    ph: 'Completed by {{count}} VIVINAUT',
    ch: 'Completed by {{count}} VIVINAUT',
  },
  earnChallenge_zero: {
    en: 'Completed by {{count}} VIVINAUT',
    ja: 'Completed by {{count}} VIVINAUT',
    et: 'Completed by {{count}} VIVINAUT',
    lt: 'Completed by {{count}} VIVINAUT',
    ph: 'Completed by {{count}} VIVINAUT',
    ch: 'Completed by {{count}} VIVINAUT',
  },
  earnChallenge_other: {
    en: 'Completed by {{count}} VIVINAUTs',
    ja: 'Completed by {{count}} VIVINAUTs',
    et: 'Completed by {{count}} VIVINAUTs',
    lt: 'Completed by {{count}} VIVINAUTs',
    ph: 'Completed by {{count}} VIVINAUTs',
    ch: 'Completed by {{count}} VIVINAUTs',
  },
  hour_one: {
    en: '{{count}} hr ',
    ja: '{{count}} hr ',
    et: '{{count}} hr ',
    lt: '{{count}} hr ',
    ph: '{{count}} hr ',
    ch: '{{count}} hr ',
  },
  hour_other: {
    en: '{{count}} hrs ',
    ja: '{{count}} hrs ',
    et: '{{count}} hrs ',
    lt: '{{count}} hrs ',
    ph: '{{count}} hrs ',
    ch: '{{count}} hrs ',
  },
  minute_one: {
    en: '{{count}} min ',
    ja: '{{count}} min ',
    et: '{{count}} min ',
    lt: '{{count}} min ',
    ph: '{{count}} min ',
    ch: '{{count}} min ',
  },
  minute_other: {
    en: '{{count}} mins ',
    ja: '{{count}} mins ',
    et: '{{count}} mins ',
    lt: '{{count}} mins ',
    ph: '{{count}} mins ',
    ch: '{{count}} mins ',
  },
  Order: {
    en: 'Order',
    ja: 'Order',
    et: 'Order',
    lt: 'Order',
    ph: 'Order',
    ch: 'Order',
  },
  'Potential Badges for Challenges': {
    en: 'Potential Badges for Challenges',
    ja: 'Potential Badges for Challenges',
    et: 'Potential Badges for Challenges',
    lt: 'Potential Badges for Challenges',
    ph: 'Potential Badges for Challenges',
    ch: 'Potential Badges for Challenges',
  },
  'Required Tools & Materials': {
    en: 'Required Tools & Materials',
    ja: 'Required Tools & Materials',
    et: 'Required Tools & Materials',
    lt: 'Required Tools & Materials',
    ph: 'Required Tools & Materials',
    ch: 'Required Tools & Materials',
  },
  Search: {
    en: 'Search',
    ja: 'Search',
    et: 'Search',
    lt: 'Search',
    ph: 'Search',
    ch: 'Search',
  },
  'Skills Badges for Challenge': {
    en: 'Skills Badges for Challenge',
    ja: 'Skills Badges for Challenge',
    et: 'Skills Badges for Challenge',
    lt: 'Skills Badges for Challenge',
    ph: 'Skills Badges for Challenge',
    ch: 'Skills Badges for Challenge',
  },
  'Steps to earn this challenge': {
    en: 'Steps to earn this challenge',
    ja: 'Steps to earn this challenge',
    et: 'Steps to earn this challenge',
    lt: 'Steps to earn this challenge',
    ph: 'Steps to earn this challenge',
    ch: 'Steps to earn this challenge',
  },
  'Talk to the creator!': {
    en: 'Talk to the creator!',
    ja: 'Talk to the creator!',
    et: 'Talk to the creator!',
    lt: 'Talk to the creator!',
    ph: 'Talk to the creator!',
    ch: 'Talk to the creator!',
  },
  'Time Taken to Complete': {
    en: 'Time Taken to Complete',
    ja: 'Time Taken to Complete',
    et: 'Time Taken to Complete',
    lt: 'Time Taken to Complete',
    ph: 'Time Taken to Complete',
    ch: 'Time Taken to Complete',
  },
  'To Infinity and Beyond: Explore the VIVIBOOM Challenge Frontier!': {
    en: 'To Infinity and Beyond: Explore the VIVIBOOM Challenge Frontier!',
    ja: 'To Infinity and Beyond: Explore the VIVIBOOM Challenge Frontier!',
    et: 'To Infinity and Beyond: Explore the VIVIBOOM Challenge Frontier!',
    lt: 'To Infinity and Beyond: Explore the VIVIBOOM Challenge Frontier!',
    ph: 'To Infinity and Beyond: Explore the VIVIBOOM Challenge Frontier!',
    ch: 'To Infinity and Beyond: Explore the VIVIBOOM Challenge Frontier!',
  },
  'Useful Tips': {
    en: 'Useful Tips',
    ja: 'Useful Tips',
    et: 'Useful Tips',
    lt: 'Useful Tips',
    ph: 'Useful Tips',
    ch: 'Useful Tips',
  },
  'View all challenges': {
    en: 'View all challenges',
    ja: 'View all challenges',
    et: 'View all challenges',
    lt: 'View all challenges',
    ph: 'View all challenges',
    ch: 'View all challenges',
  },
  'VIVIBOOM Adventures: Each Challenge a Cosmic Victory!': {
    en: 'VIVIBOOM Adventures: Each Challenge a Cosmic Victory!',
    ja: 'VIVIBOOM Adventures: Each Challenge a Cosmic Victory!',
    et: 'VIVIBOOM Adventures: Each Challenge a Cosmic Victory!',
    lt: 'VIVIBOOM Adventures: Each Challenge a Cosmic Victory!',
    ph: 'VIVIBOOM Adventures: Each Challenge a Cosmic Victory!',
    ch: 'VIVIBOOM Adventures: Each Challenge a Cosmic Victory!',
  },
  'VIVINAUT Projects for this Challenge': {
    en: 'VIVINAUT Projects for this Challenge',
    ja: 'VIVINAUT Projects for this Challenge',
    et: 'VIVINAUT Projects for this Challenge',
    lt: 'VIVINAUT Projects for this Challenge',
    ph: 'VIVINAUT Projects for this Challenge',
    ch: 'VIVINAUT Projects for this Challenge',
  },
  whereToStartAll: {
    en: 'Not sure where to start? Try these! You need to win all of these challenges to become a VIVINAUT!',
    ja: 'Not sure where to start? Try these! You need to win all of these challenges to become a VIVINAUT!',
    et: 'Not sure where to start? Try these! You need to win all of these challenges to become a VIVINAUT!',
    lt: 'Not sure where to start? Try these! You need to win all of these challenges to become a VIVINAUT!',
    ph: 'Not sure where to start? Try these! You need to win all of these challenges to become a VIVINAUT!',
    ch: 'Not sure where to start? Try these! You need to win all of these challenges to become a VIVINAUT!',
  },
  whereToStart: {
    en: 'Not sure where to start? Try these! Earn at least {{count}} of these challenges to become a VIVINAUT!',
    ja: 'Not sure where to start? Try these! Earn at least {{count}} of these challenges to become a VIVINAUT!',
    et: 'Not sure where to start? Try these! Earn at least {{count}} of these challenges to become a VIVINAUT!',
    lt: 'Not sure where to start? Try these! Earn at least {{count}} of these challenges to become a VIVINAUT!',
    ph: 'Not sure where to start? Try these! Earn at least {{count}} of these challenges to become a VIVINAUT!',
    ch: 'Not sure where to start? Try these! Earn at least {{count}} of these challenges to become a VIVINAUT!',
  },
  "Yay! You've completed this challenge": {
    en: "Yay! You've completed this challenge",
    ja: "Yay! You've completed this challenge",
    et: "Yay! You've completed this challenge",
    lt: "Yay! You've completed this challenge",
    ph: "Yay! You've completed this challenge",
    ch: "Yay! You've completed this challenge",
  },
};

export const ChallengeOrderType = {
    LATEST: 'LATEST',
    OLDEST: 'OLDEST',
    RANDOM: 'RANDOM',
  };
  
  export const challengeOrderTypes = [
    ChallengeOrderType.LATEST,
    ChallengeOrderType.OLDEST,
    ChallengeOrderType.RANDOM,
  ];
  
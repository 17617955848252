export default {
  "Yay! You've earned this badge": {
    en: "Yay! You've earned this badge",
    ja: 'わーい！このバッジを獲得しました',
    et: 'Juhei! Oled välja teeninud selle märgi',
    lt: 'Valio, užsitarnavai šį ženkliuką!',
    ph: "Yay! You've earned this badge",
    ch: "Yay! You've earned this badge",
  },
  'Challenges for this Badge': {
    en: 'Challenges for this Badge',
    ja: 'Challenges for this Badge',
    et: 'Challenges for this Badge',
    lt: 'Challenges for this Badge',
    ph: 'Challenges for this Badge',
    ch: 'Challenges for this Badge',
  },
  VIVINAUTS: {
    en: 'VIVINAUTS',
    ja: 'VIVINAUTS',
    et: 'Vivinaudid',
    lt: 'VIVINAUTAI',
    ph: 'VIVINAUTS',
    ch: 'VIVINAUTS',
  },
  VIVINAUT: {
    en: 'VIVINAUT',
    ja: 'VIVINAUT',
    et: 'Vivinaut',
    lt: 'VIVINAUTAS',
    ph: 'VIVINAUT',
    ch: 'VIVINAUT',
  },
  day_one: {
    en: '{{count}} day ',
    ja: '{{count}} day ',
    et: '{{count}} day ',
    lt: '{{count}} day ',
    ph: '{{count}} day ',
    ch: '{{count}} day ',
  },
  day_other: {
    en: '{{count}} days ',
    ja: '{{count}} days ',
    et: '{{count}} days ',
    lt: '{{count}} days ',
    ph: '{{count}} days ',
    ch: '{{count}} days ',
  },
  hour_one: {
    en: '{{count}} hour ',
    ja: '{{count}} hour ',
    et: '{{count}} hour ',
    lt: '{{count}} hour ',
    ph: '{{count}} hour ',
    ch: '{{count}} hour ',
  },
  hour_other: {
    en: '{{count}} hours ',
    ja: '{{count}} hours ',
    et: '{{count}} hours ',
    lt: '{{count}} hours ',
    ph: '{{count}} hours ',
    ch: '{{count}} hours ',
  },
  minute_one: {
    en: '{{count}} minute ',
    ja: '{{count}} minute ',
    et: '{{count}} minute ',
    lt: '{{count}} minute ',
    ph: '{{count}} minute ',
    ch: '{{count}} minute ',
  },
  minute_other: {
    en: '{{count}} minutes ',
    ja: '{{count}} minutes ',
    et: '{{count}} minutes ',
    lt: '{{count}} minutes ',
    ph: '{{count}} minutes ',
    ch: '{{count}} minutes ',
  },
  earnBadge_one: {
    en: '{{count}} VIVINAUT earned this badge!',
    ja: '{{count}} VIVINAUT がこのバッジを獲得しました!',
    et: '{{count}} VIVINAUT on teeninud selle märgi',
    lt: '{{count}} VIVINAUTAS užtarnavo šį ženkliuką',
    ph: '{{count}} VIVINAUT earned this badge!',
    ch: '{{count}} VIVINAUT earned this badge!',
  },
  earnBadge_zero: {
    en: '{{count}} VIVINAUT earned this badge!',
    ja: '{{count}} VIVINAUT がこのバッジを獲得しました!',
    et: '{{count}} VIVINAUT on teeninud selle märgi',
    lt: '{{count}} VIVINAUTŲ užtarnavo šį ženkliuką',
    ph: '{{count}} VIVINAUT earned this badge!',
    ch: '{{count}} VIVINAUT earned this badge!',
  },
  earnBadge_other: {
    en: '{{count}} VIVINAUTs earned this badge!',
    ja: '{{count}} 人の VIVINAUT がこのバッジを獲得しました!',
    et: '{{count}} VIVINAUTi on teeninud selle märgi',
    lt: '{{count}} VIVINAUTŲ užtarnavo šį ženkliuką',
    ph: '{{count}} VIVINAUTs earned this badge!',
    ch: '{{count}} VIVINAUTs earned this badge!',
  },
  'View Badge': {
    en: 'View Badge',
    ja: 'バッジを見る',
    et: 'Vaata märki',
    lt: 'Peržiūrėti ženkliuką',
    ph: 'View Badge',
    ch: 'View Badge',
  },
  "Don't know what to do next?": {
    en: "Don't know what to do next?",
    ja: '次に何をすべきかわからない？',
    et: 'Ei tea, mida teha järgmiseks?',
    lt: 'Nežinai ką daryt toliau?',
    ph: "Don't know what to do next?",
    ch: "Don't know what to do next?",
  },
  clickHandle: {
    en: 'Click the <1>red</1> handle for a random badge!',
    ja: '<1>赤い</1>ハンドルをクリックして、ランダムなバッジを獲得してください!',
    et: 'Kliki <1>punasel nupul</1>, et teenida mõni juhuslik märk!',
    lt: 'Paspausk ant <1>raudonas</1>, kad pasirinktum atsitiktinį ženkliuką!',
    ph: 'Click the <1>red</1> handle for a random badge!',
    ch: 'Click the <1>red</1> handle for a random badge!',
  },
  Badge: {
    en: 'Badge',
    ja: 'バッジ',
    et: 'Märk',
    lt: 'Ženkliukas',
    ph: 'Badge',
    ch: 'Badge',
  },
  'Earn a badge from the VIVIBOOM Universe!': {
    en: 'Earn a badge from the VIVIBOOM Universe!',
    ja: 'VIVIBOOM ユニバースからバッジを獲得しましょう!',
    et: 'Võida märk VIVIBOOMist!',
    lt: 'Užsitarnauk ženkliuką iš VIVIBOOM visatos!',
    ph: 'Earn a badge from the VIVIBOOM Universe!',
    ch: 'Earn a badge from the VIVIBOOM Universe!',
  },
  Project: {
    en: 'Project',
    ja: 'プロジェクト',
    et: 'Projekt',
    lt: 'Projektas',
    ph: 'Project',
    ch: 'Project',
  },
  Projects: {
    en: 'Projects',
    ja: 'プロジェクト',
    et: 'Projektid',
    lt: 'Projektai',
    ph: 'Projects',
    ch: 'Projects',
  },
  awardProject_one: {
    en: 'Awarded {{count}} Project',
    ja: '{{count}} 件のプロジェクトを受賞',
    et: 'Antud {{count}} projektis',
    lt: 'Apdovanotas {{count}} projektas',
    ph: 'Awarded {{count}} Project',
    ch: 'Awarded {{count}} Project',
  },
  awardProject_zero: {
    en: 'Awarded {{count}} Project',
    ja: '{{count}} 件のプロジェクトを受賞',
    et: 'Antud {{count}} projektis',
    lt: 'Apdovanotas {{count}} projektas',
    ph: 'Awarded {{count}} Project',
    ch: 'Awarded {{count}} Project',
  },
  awardProject_other: {
    en: 'Awarded {{count}} Projects',
    ja: '{{count}} 件のプロジェクトを受賞',
    et: 'Antud {{count}} projektidele',
    lt: 'Apdovanoti {{count}} projektai',
    ph: 'Awarded {{count}} Projects',
    ch: 'Awarded {{count}} Projects',
  },
  earnedBy_one: {
    en: 'Earned by {{count}} VIVINAUT',
    ja: '{{count}} VIVINAUT が獲得',
    et: '{{count}} VIVINAUT on teeninud selle märgi',
    lt: 'Užtarnavo {{count}} VIVINAUTAS',
    ph: 'Earned by {{count}} VIVINAUT',
    ch: 'Earned by {{count}} VIVINAUT',
  },
  earnedBy_zero: {
    en: 'Earned by {{count}} VIVINAUTs',
    ja: '{{count}} VIVINAUT が獲得',
    et: '{{count}} VIVINAUTi on teeninud selle märgi',
    lt: 'Užtarnavo {{count}} VIVINAUTŲ',
    ph: 'Earned by {{count}} VIVINAUTs',
    ch: 'Earned by {{count}} VIVINAUTs',
  },
  earnedBy_other: {
    en: 'Earned by {{count}} VIVINAUTs',
    ja: '{{count}} VIVINAUT が獲得',
    et: '{{count}} VIVINAUTi on teeninud selle märgi',
    lt: 'Užtarnavo {{count}} VIVINAUTŲ',
    ph: 'Earned by {{count}} VIVINAUTs',
    ch: 'Earned by {{count}} VIVINAUTs',
  },
  'Created By': {
    en: 'Created By',
    ja: '著者',
    et: 'Autor',
    lt: 'Sukūrė',
    ph: 'Created By',
    ch: 'Created By',
  },
  'How to earn this shiny badge?': {
    en: 'How to earn this shiny badge?',
    ja: 'この輝くバッジを獲得するには？',
    et: 'Kuidas sellist märki saada?',
    lt: 'Kaip užsitarnauti šį ypatingą ženkliuką?',
    ph: 'How to earn this shiny badge?',
    ch: 'How to earn this shiny badge?',
  },
  'Submit a project': {
    en: 'Submit a project',
    ja: 'プロジェクトを追加',
    et: 'Esita projekt',
    lt: 'Pateikti projektą',
    ph: 'Submit a project',
    ch: 'Submit a project',
  },
  'Add project': {
    en: 'Add project',
    ja: 'Add project',
    et: 'Add project',
    lt: 'Add project',
    ph: 'Add project',
    ch: 'Add project',
  },
  'Talk to a mentor': {
    en: 'Talk to a mentor',
    ja: 'メンターに相談する',
    et: 'Suhtle mentoriga',
    lt: 'Pakalbėk su mentorium',
    ph: 'Talk to a mentor',
    ch: 'Talk to a mentor',
  },
  'Projects for this badge by VIVINAUTS!': {
    en: 'Projects for this badge by VIVINAUTS!',
    ja: 'VIVINAUTSによるこのバッジのプロジェクト！',
    et: 'Selle märgiga seotud projektid',
    lt: 'Kitų VIVINAUTŲ projektai šiam ženkliukui gauti',
    ph: 'Projects for this badge by VIVINAUTS!',
    ch: 'Projects for this badge by VIVINAUTS!',
  },
  submitProject: {
    en: 'No project has been added to this badge! Be the first to <1>submit</1>!',
    ja: 'このバッジに追加されたプロジェクトはありません!最初に<1>提出</1>してください!',
    et: 'Selle märgiga pole seotud ühtki projekti. Ole esimene, kes <1>esitab projekti</1>!',
    lt: 'Joks projektas nebuvo pateiktas šiam ženkliukui gauti! Būk pirmas/a ir <1>pateik</1> savo!',
    ph: 'No project has been added to this badge! Be the first to <1>submit</1>!',
    ch: 'No project has been added to this badge! Be the first to <1>submit</1>!',
  },
  Badges: {
    en: 'Badges',
    ja: 'バッジ',
    et: 'Märgid',
    lt: 'Ženkliukai',
    ph: 'Badges',
    ch: 'Badges',
  },
  'Badge Roulette': {
    en: 'Badge Roulette',
    ja: 'バッジルーレット',
    et: 'Märkide rulett',
    lt: 'Ženkliukų ruletė',
    ph: 'Badge Roulette',
    ch: 'Badge Roulette',
  },
  'All Badges': {
    en: 'All Badges',
    ja: 'すべてのバッジ',
    et: 'Kõik märgid',
    lt: 'Visi ženkliukai',
    ph: 'All Badges',
    ch: 'All Badges',
  },
  Guides: {
    en: 'Guides',
    ja: 'ガイド',
    et: 'Juhendid',
    lt: 'Nuorodos',
    ph: 'Guides',
    ch: 'Guides',
  },
  'Search Badges': {
    en: 'Search Badges',
    ja: '検索バッジ',
    et: 'Otsi märgid',
    lt: 'Ieškoti ženkliukų',
    ph: 'Search Badges',
    ch: 'Search Badges',
  },
  'Wheel of Badges!': {
    en: 'Wheel of Badges!',
    ja: 'バッジの輪！',
    et: 'Märgiratas!',
    lt: 'Ženkliukų ratas',
    ph: 'Wheel of Badges!',
    ch: 'Wheel of Badges!',
  },
  'Earn a random badge!': {
    en: 'Earn a random badge!',
    ja: 'ランダムバッジを獲得！',
    et: 'Teeni mõni juhuslik märk',
    lt: 'Užsitarnauk atsitiktinį ženkliuką!',
    ph: 'Earn a random badge!',
    ch: 'Earn a random badge!',
  },
  'Starter Badges': {
    en: 'Starter Badges',
    ja: 'Starter Badges',
    et: 'Starter Badges',
    lt: 'Starter Badges',
    ph: 'Starter Badges',
    ch: 'Starter Badges',
  },
  whereToStartAll: {
    en: 'Not sure where to start? Try these! You need to win all of these badges to become a VIVINAUT!',
    ja: 'Not sure where to start? Try these! You need to win all of these badges to become a VIVINAUT!',
    et: 'Not sure where to start? Try these! You need to win all of these badges to become a VIVINAUT!',
    lt: 'Not sure where to start? Try these! You need to win all of these badges to become a VIVINAUT!',
    ph: 'Not sure where to start? Try these! You need to win all of these badges to become a VIVINAUT!',
    ch: 'Not sure where to start? Try these! You need to win all of these badges to become a VIVINAUT!',
  },
  whereToStart: {
    en: 'Not sure where to start? Try these! Earn at least {{count}} of these badges to become a VIVINAUT!',
    ja: 'Not sure where to start? Try these! Earn at least {{count}} of these badges to become a VIVINAUT!',
    et: 'Not sure where to start? Try these! Earn at least {{count}} of these badges to become a VIVINAUT!',
    lt: 'Not sure where to start? Try these! Earn at least {{count}} of these badges to become a VIVINAUT!',
    ph: 'Not sure where to start? Try these! Earn at least {{count}} of these badges to become a VIVINAUT!',
    ch: 'Not sure where to start? Try these! Earn at least {{count}} of these badges to become a VIVINAUT!',
  },
  'Browse through random badges here!': {
    en: 'Browse through random badges here!',
    ja: 'ここでランダムなバッジを参照してください!',
    et: 'Tutvu märkidega!',
    lt: 'Čia peržiūrėk įvairius ženkliukus',
    ph: 'Browse through random badges here!',
    ch: 'Browse through random badges here!',
  },
  'Yay! You have seen it all': {
    en: 'Yay! You have seen it all',
    ja: 'わーい！あなたはそれをすべて見てきました',
    et: 'Juhei! Kõik märgid läbi vaadatud.',
    lt: 'Valio, viską peržiūrėjai',
    ph: 'Yay! You have seen it all',
    ch: 'Yay! You have seen it all',
  },
  "Let's add to your badge collection here!": {
    en: "Let's add to your badge collection here!",
    ja: 'ここであなたのバッジコレクションに加えましょう!',
    et: 'Lisa märk oma märgikollektsiooni!',
    lt: 'Pridėk prie savo ženkliukų kolekcijos čia',
    ph: "Let's add to your badge collection here!",
    ch: "Let's add to your badge collection here!",
  },
  'All Categories': {
    en: 'All Categories',
    ja: 'すべてのカテゴリ',
    et: 'Kõik kategooriad',
    lt: 'Visos kategorijos',
    ph: 'All Categories',
    ch: 'All Categories',
  },
  'Check out the badges you can earn once you become a VIVINAUT!': {
    en: 'Check out the badges you can earn once you become a VIVINAUT!',
    ja: 'Check out the badges you can earn once you become a VIVINAUT!',
    et: 'Check out the badges you can earn once you become a VIVINAUT!',
    lt: 'Check out the badges you can earn once you become a VIVINAUT!',
    ph: 'Check out the badges you can earn once you become a VIVINAUT!',
    ch: 'Check out the badges you can earn once you become a VIVINAUT!',
  },
  'View all badges': {
    en: 'View all badges',
    ja: 'View all badges',
    et: 'View all badges',
    lt: 'View all badges',
    ph: 'View all badges',
    ch: 'View all badges',
  },
  'About this badge': {
    en: 'About this badge',
    ja: 'About this badge',
    et: 'About this badge',
    lt: 'About this badge',
    ph: 'About this badge',
    ch: 'About this badge',
  },
  'Required Tools & Materials': {
    en: 'Required Tools & Materials',
    ja: 'Required Tools & Materials',
    et: 'Required Tools & Materials',
    lt: 'Required Tools & Materials',
    ph: 'Required Tools & Materials',
    ch: 'Required Tools & Materials',
  },
  'Steps to earn this badge': {
    en: 'Steps to earn this badge',
    ja: 'Steps to earn this badge',
    et: 'Steps to earn this badge',
    lt: 'Steps to earn this badge',
    ph: 'Steps to earn this badge',
    ch: 'Steps to earn this badge',
  },
  'Useful Tips': {
    en: 'Useful Tips',
    ja: 'Useful Tips',
    et: 'Useful Tips',
    lt: 'Useful Tips',
    ph: 'Useful Tips',
    ch: 'Useful Tips',
  },
  'Commonly Asked Questions': {
    en: 'Commonly Asked Questions',
    ja: 'Commonly Asked Questions',
    et: 'Commonly Asked Questions',
    lt: 'Commonly Asked Questions',
    ph: 'Commonly Asked Questions',
    ch: 'Commonly Asked Questions',
  },
  'Talk to the creator!': {
    en: 'Talk to the creator!',
    ja: 'Talk to the creator!',
    et: 'Talk to the creator!',
    lt: 'Talk to the creator!',
    ph: 'Talk to the creator!',
    ch: 'Talk to the creator!',
  },
};

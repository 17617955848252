export default {
  'Read more': {
    en: 'Read more',
    ja: 'もっと詳しく',
    et: 'Loe edasi',
    lt: 'Skaityti daugiau',
    ph: 'Read more',
    ch: 'Read more',
  },
  'Read less': {
    en: 'Read less',
    ja: 'もっと少なく',
    et: 'Loe vähem',
    lt: 'Matyti mažiau',
    ph: 'Read less',
    ch: 'Read less',
  },
  'Upcoming Events': {
    en: 'Upcoming Events',
    ja: '今後のイベント',
    et: 'Tulevased sündmused',
    lt: 'Vyksiantys renginiai',
    ph: 'Upcoming Events',
    ch: 'Upcoming Events',
  },
  'Events and Workshops': {
    en: 'Events and Workshops',
    ja: 'イベントとワークショップ',
    et: 'Sündmused ja töötoad',
    lt: 'Renginiai ir dirbtuvės',
    ph: 'Events and Workshops',
    ch: 'Events and Workshops',
  },
  'There are no upcoming workshops at the moment. Check back soon!': {
    en: 'There are no upcoming workshops at the moment. Check back soon!',
    ja: '公開中のイベントがありません。またチェックしてください！',
    et: 'Hetkel ei ole töötubasid plaanis. Vaata varsti uuesti.',
    lt: 'Šiuo metu nėra vyksiančių renginių. Patikrink vėl šiek tiek vėliau. ',
    ph: 'There are no upcoming workshops at the moment. Check back soon!',
    ch: 'There are no upcoming workshops at the moment. Check back soon!',
  },
  'Book a Workshop': {
    en: 'Book a Workshop',
    ja: 'ワークショップを予約する',
    et: 'Broneeri koht töötuppa',
    lt: 'Rezervuok dirbtuves',
    ph: 'Book a Workshop',
    ch: 'Book a Workshop',
  },
  'Your AI Friend': {
    en: 'Your AI Friend',
    ja: 'Your AI Friend',
    et: 'Your AI Friend',
    lt: 'Your AI Friend',
    ph: 'Your AI Friend',
    ch: 'Your AI Friend',
  },
  'Build with BuilderPal': {
    en: 'Build with BuilderPal',
    ja: 'Build with BuilderPal',
    et: 'Build with BuilderPal',
    lt: 'Build with BuilderPal',
    ph: 'Build with BuilderPal',
    ch: 'Build with BuilderPal',
  },
  builderPalSubtitle: {
    en: 'Meet your project pal to whip up something absolutely amaaazzzziiing together!',
    ja: 'Meet your project pal to whip up something absolutely amaaazzzziiing together!',
    et: 'Meet your project pal to whip up something absolutely amaaazzzziiing together!',
    lt: 'Meet your project pal to whip up something absolutely amaaazzzziiing together!',
    ph: 'Meet your project pal to whip up something absolutely amaaazzzziiing together!',
    ch: 'Meet your project pal to whip up something absolutely amaaazzzziiing together!',
  },
  'Are you ready?': {
    en: 'Are you ready?',
    ja: 'Are you ready?',
    et: 'Are you ready?',
    lt: 'Are you ready?',
    ph: 'Are you ready?',
    ch: 'Are you ready?',
  },
  "Let's Go!": {
    en: "Let's Go!",
    ja: "Let's Go!",
    et: "Let's Go!",
    lt: "Let's Go!",
    ph: "Let's Go!",
    ch: "Let's Go!",
  },
  'Welcome To': {
    en: 'Welcome To',
    ja: 'Welcome To',
    et: 'Welcome To',
    lt: 'Welcome To',
    ph: 'Welcome To',
    ch: 'Welcome To',
  },
};

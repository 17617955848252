export default {
  'Back to wallet page': {
    en: 'Back to wallet page',
    ja: 'Back to wallet page',
    et: 'Back to wallet page',
    lt: 'Back to wallet page',
    ph: 'Back to wallet page',
    ch: 'Back to wallet page',
  },
  'Claim Reward': {
    en: 'Claim Reward',
    ja: 'Claim Reward',
    et: 'Claim Reward',
    lt: 'Claim Reward',
    ph: 'Claim Reward',
    ch: 'Claim Reward',
  },
  Confirm: {
    en: 'Confirm',
    ja: 'Confirm',
    et: 'Confirm',
    lt: 'Confirm',
    ph: 'Confirm',
    ch: 'Confirm',
  },
  CONGRATULATIONS: {
    en: 'CONGRATULATIONS',
    ja: 'CONGRATULATIONS',
    et: 'CONGRATULATIONS',
    lt: 'CONGRATULATIONS',
    ph: 'CONGRATULATIONS',
    ch: 'CONGRATULATIONS',
  },
  'Enter reward code': {
    en: 'Enter reward code',
    ja: 'Enter reward code',
    et: 'Enter reward code',
    lt: 'Enter reward code',
    ph: 'Enter reward code',
    ch: 'Enter reward code',
  },
  'fetching rewards from space station...': {
    en: 'fetching rewards from space station...',
    ja: 'fetching rewards from space station...',
    et: 'fetching rewards from space station...',
    lt: 'fetching rewards from space station...',
    ph: 'fetching rewards from space station...',
    ch: 'fetching rewards from space station...',
  },
  'Get your Reward': {
    en: 'Get your Reward',
    ja: 'Get your Reward',
    et: 'Get your Reward',
    lt: 'Get your Reward',
    ph: 'Get your Reward',
    ch: 'Get your Reward',
  },
  Logout: {
    en: 'Logout',
    ja: 'Logout',
    et: 'Logout',
    lt: 'Logout',
    ph: 'Logout',
    ch: 'Logout',
  },
  'My Profile': {
    en: 'My Profile',
    ja: 'My Profile',
    et: 'My Profile',
    lt: 'My Profile',
    ph: 'My Profile',
    ch: 'My Profile',
  },
  'My Bookings': {
    en: 'My Bookings',
    ja: 'My Bookings',
    et: 'My Bookings',
    lt: 'My Bookings',
    ph: 'My Bookings',
    ch: 'My Bookings',
  },
  'Oops! It seems like you do not have a wallet yet.': {
    en: 'Oops! It seems like you do not have a wallet yet.',
    ja: 'Oops! It seems like you do not have a wallet yet.',
    et: 'Oops! It seems like you do not have a wallet yet.',
    lt: 'Oops! It seems like you do not have a wallet yet.',
    ph: 'Oops! It seems like you do not have a wallet yet.',
    ch: 'Oops! It seems like you do not have a wallet yet.',
  },
  'Oops! Something went wrong...': {
    en: 'Oops! Something went wrong...',
    ja: 'Oops! Something went wrong...',
    et: 'Oops! Something went wrong...',
    lt: 'Oops! Something went wrong...',
    ph: 'Oops! Something went wrong...',
    ch: 'Oops! Something went wrong...',
  },
  'Please check that': {
    en: 'Please check that',
    ja: 'Please check that',
    et: 'Please check that',
    lt: 'Please check that',
    ph: 'Please check that',
    ch: 'Please check that',
  },
  'Please try again later': {
    en: 'Please try again later',
    ja: 'Please try again later',
    et: 'Please try again later',
    lt: 'Please try again later',
    ph: 'Please try again later',
    ch: 'Please try again later',
  },
  'Promo Codes are usually limited to one use per person.': {
    en: 'Promo Codes are usually limited to one use per person.',
    ja: 'Promo Codes are usually limited to one use per person.',
    et: 'Promo Codes are usually limited to one use per person.',
    lt: 'Promo Codes are usually limited to one use per person.',
    ph: 'Promo Codes are usually limited to one use per person.',
    ch: 'Promo Codes are usually limited to one use per person.',
  },
  'Redeem Rewards': {
    en: 'Redeem Rewards',
    ja: 'Redeem Rewards',
    et: 'Redeem Rewards',
    lt: 'Redeem Rewards',
    ph: 'Redeem Rewards',
    ch: 'Redeem Rewards',
  },
  'Redeem your Promo Code to get free gifts and much more!': {
    en: 'Redeem your Promo Code to get free gifts and much more!',
    ja: 'Redeem your Promo Code to get free gifts and much more!',
    et: 'Redeem your Promo Code to get free gifts and much more!',
    lt: 'Redeem your Promo Code to get free gifts and much more!',
    ph: 'Redeem your Promo Code to get free gifts and much more!',
    ch: 'Redeem your Promo Code to get free gifts and much more!',
  },
  The: {
    en: 'The',
    ja: 'The',
    et: 'The',
    lt: 'The',
    ph: 'The',
    ch: 'The',
  },
  Settings: {
    en: 'Settings',
    ja: 'Settings',
    et: 'Settings',
    lt: 'Settings',
    ph: 'Settings',
    ch: 'Settings',
  },
  'Vivicoin Rewards': {
    en: 'Vivicoin Rewards',
    ja: 'Vivicoin Rewards',
    et: 'Vivicoin Rewards',
    lt: 'Vivicoin Rewards',
    ph: 'Vivicoin Rewards',
    ch: 'Vivicoin Rewards',
  },
  VIVICOINS: {
    en: 'VIVICOINS',
    ja: 'VIVICOINS',
    et: 'VIVICOINS',
    lt: 'VIVICOINS',
    ph: 'VIVICOINS',
    ch: 'VIVICOINS',
  },
  VIVICOIN_one: {
    en: '{{ count }} VIVICOIN',
    ja: '{{ count }} VIVICOIN',
    et: '{{ count }} VIVICOIN',
    lt: '{{ count }} VIVICOIN',
    ph: '{{ count }} VIVICOIN',
    ch: '{{ count }} VIVICOIN',
  },
  VIVICOIN_other: {
    en: '{{ count }} VIVICOINS',
    ja: '{{ count }} VIVICOINS',
    et: '{{ count }} VIVICOINS',
    lt: '{{ count }} VIVICOINS',
    ph: '{{ count }} VIVICOINS',
    ch: '{{ count }} VIVICOINS',
  },
  'Unlock a world of rewards as new surprises await with each visit!': {
    en: 'Unlock a world of rewards as new surprises await with each visit!',
    ja: 'Unlock a world of rewards as new surprises await with each visit!',
    et: 'Unlock a world of rewards as new surprises await with each visit!',
    lt: 'Unlock a world of rewards as new surprises await with each visit!',
    ph: 'Unlock a world of rewards as new surprises await with each visit!',
    ch: 'Unlock a world of rewards as new surprises await with each visit!',
  },
  'You have successfully redeemed': {
    en: 'You have successfully redeemed',
    ja: 'You have successfully redeemed',
    et: 'You have successfully redeemed',
    lt: 'You have successfully redeemed',
    ph: 'You have successfully redeemed',
    ch: 'You have successfully redeemed',
  },
};

export default {
  Back: {
    en: 'Back',
    ja: 'Back',
    et: 'Back',
    lt: 'Back',
    ph: 'Back',
    ch: 'Back',
  },
  Challenges: {
    en: 'Challenges',
    ja: 'Challenges',
    et: 'Challenges',
    lt: 'Challenges',
    ph: 'Challenges',
    ch: 'Challenges',
  },
  Close: {
    en: 'Close',
    ja: 'Close',
    et: 'Close',
    lt: 'Close',
    ph: 'Close',
    ch: 'Close',
  },
  Next: {
    en: 'Next',
    ja: 'Next',
    et: 'Next',
    lt: 'Next',
    ph: 'Next',
    ch: 'Next',
  },
  Skip: {
    en: 'Skip',
    ja: 'Skip',
    et: 'Skip',
    lt: 'Skip',
    ph: 'Skip',
    ch: 'Skip',
  },
  naviContent1: {
    en: "Try these buttons to explore VIVIBOOM's different features!",
    ja: "Try these buttons to explore VIVIBOOM's different features!",
    et: "Try these buttons to explore VIVIBOOM's different features!",
    lt: "Try these buttons to explore VIVIBOOM's different features!",
    ph: "Try these buttons to explore VIVIBOOM's different features!",
    ch: "Try these buttons to explore VIVIBOOM's different features!",
  },
  naviContent2: {
    en: 'See your chat messages, notifications, language, or change your profile and other settings here.',
    ja: 'See your chat messages, notifications, language, or change your profile and other settings here.',
    et: 'See your chat messages, notifications, language, or change your profile and other settings here.',
    lt: 'See your chat messages, notifications, language, or change your profile and other settings here.',
    ph: 'See your chat messages, notifications, language, or change your profile and other settings here.',
    ch: 'See your chat messages, notifications, language, or change your profile and other settings here.',
  },
  mobileNaviContent1: {
    en: 'Tap here to open the menu.',
    ja: 'Tap here to open the menu.',
    et: 'Tap here to open the menu.',
    lt: 'Tap here to open the menu.',
    ph: 'Tap here to open the menu.',
    ch: 'Tap here to open the menu.',
  },
  mobileNaviContent2: {
    en: "Try these buttons to explore VIVIBOOM's different features!",
    ja: "Try these buttons to explore VIVIBOOM's different features!",
    et: "Try these buttons to explore VIVIBOOM's different features!",
    lt: "Try these buttons to explore VIVIBOOM's different features!",
    ph: "Try these buttons to explore VIVIBOOM's different features!",
    ch: "Try these buttons to explore VIVIBOOM's different features!",
  },
  mobileNaviContent3: {
    en: 'Edit your profile and settings, see your bookings, or logout here.',
    ja: 'Edit your profile and settings, see your bookings, or logout here.',
    et: 'Edit your profile and settings, see your bookings, or logout here.',
    lt: 'Edit your profile and settings, see your bookings, or logout here.',
    ph: 'Edit your profile and settings, see your bookings, or logout here.',
    ch: 'Edit your profile and settings, see your bookings, or logout here.',
  },
  mobileNaviContent4: {
    en: 'Lastly, you can see your chat messages and notifications, or change the language here.',
    ja: 'Lastly, you can see your chat messages and notifications, or change the language here.',
    et: 'Lastly, you can see your chat messages and notifications, or change the language here.',
    lt: 'Lastly, you can see your chat messages and notifications, or change the language here.',
    ph: 'Lastly, you can see your chat messages and notifications, or change the language here.',
    ch: 'Lastly, you can see your chat messages and notifications, or change the language here.',
  },
  Badges: {
    en: 'Badges',
    ja: 'Badges',
    et: 'Badges',
    lt: 'Badges',
    ph: 'Badges',
    ch: 'Badges',
  },
  badgesContent1: {
    en: 'Here you can see the different badges waiting to join your collection.',
    ja: 'Here you can see the different badges waiting to join your collection.',
    et: 'Here you can see the different badges waiting to join your collection.',
    lt: 'Here you can see the different badges waiting to join your collection.',
    ph: 'Here you can see the different badges waiting to join your collection.',
    cn: 'Here you can see the different badges waiting to join your collection.',
  },
  badgesContent2: {
    en: 'Press on any badge to see how you can earn it!',
    ja: 'Press on any badge to see how you can earn it!',
    et: 'Press on any badge to see how you can earn it!',
    lt: 'Press on any badge to see how you can earn it!',
    ph: 'Press on any badge to see how you can earn it!',
    cn: 'Press on any badge to see how you can earn it!',
  },
  challengesContent1: {
    en: 'Here you can see the different challenges waiting to join your collection.',
    ja: 'Here you can see the different challenges waiting to join your collection.',
    et: 'Here you can see the different challenges waiting to join your collection.',
    lt: 'Here you can see the different challenges waiting to join your collection.',
    ph: 'Here you can see the different challenges waiting to join your collection.',
    cn: 'Here you can see the different challenges waiting to join your collection.',
  },
  challengesContent2: {
    en: 'Press on any challenge to see how you can earn it!',
    ja: 'Press on any challenge to see how you can earn it!',
    et: 'Press on any challenge to see how you can earn it!',
    lt: 'Press on any challenge to see how you can earn it!',
    ph: 'Press on any challenge to see how you can earn it!',
    cn: 'Press on any challenge to see how you can earn it!',
  },
  starterBadgesContent1: {
    en: 'Here you can see the different badges waiting to join your collection.',
    ja: 'Here you can see the different badges waiting to join your collection.',
    et: 'Here you can see the different badges waiting to join your collection.',
    lt: 'Here you can see the different badges waiting to join your collection.',
    ph: 'Here you can see the different badges waiting to join your collection.',
    cn: 'Here you can see the different badges waiting to join your collection.',
  },
  starterBadgesContent2: {
    en: 'Right now, you are an EXPLORER. To become a VIVINAUT and officially join the VIVIBOOM community, '
    + 'you must first prove yourself by earning these required starter badges! '
    + 'Press on any badge to see how you can earn it.',
    ja: 'Right now, you are an EXPLORER. To become a VIVINAUT and officially join the VIVIBOOM community, '
    + 'you must first prove yourself by earning these required starter badges! '
    + 'Press on any badge to see how you can earn it.',
    et: 'Right now, you are an EXPLORER. To become a VIVINAUT and officially join the VIVIBOOM community, '
    + 'you must first prove yourself by earning these required starter badges! '
    + 'Press on any badge to see how you can earn it.',
    lt: 'Right now, you are an EXPLORER. To become a VIVINAUT and officially join the VIVIBOOM community, '
    + 'you must first prove yourself by earning these required starter badges! '
    + 'Press on any badge to see how you can earn it.',
    ph: 'Right now, you are an EXPLORER. To become a VIVINAUT and officially join the VIVIBOOM community, '
    + 'you must first prove yourself by earning these required starter badges! '
    + 'Press on any badge to see how you can earn it.',
    cn: 'Right now, you are an EXPLORER. To become a VIVINAUT and officially join the VIVIBOOM community, '
    + 'you must first prove yourself by earning these required starter badges! '
    + 'Press on any badge to see how you can earn it.',
  },
  Projects: {
    en: 'Projects',
    ja: 'Projects',
    et: 'Projects',
    lt: 'Projects',
    ph: 'Projects',
    cn: 'Projects',
  },
  projectsContent1: {
    en: 'Here, you can see the awesome projects of your fellow creators.',
    ja: 'Here, you can see the awesome projects of your fellow creators.',
    et: 'Here, you can see the awesome projects of your fellow creators.',
    lt: 'Here, you can see the awesome projects of your fellow creators.',
    ph: 'Here, you can see the awesome projects of your fellow creators.',
    cn: 'Here, you can see the awesome projects of your fellow creators.',
  },
  projectsContent2: {
    en: "Don't be shy to add your own project too!",
    ja: "Don't be shy to add your own project too!",
    et: "Don't be shy to add your own project too!",
    lt: "Don't be shy to add your own project too!",
    ph: "Don't be shy to add your own project too!",
    cn: "Don't be shy to add your own project too!",
  },
  projectsContent3: {
    en: 'Choose a project category here.',
    ja: 'Choose a project category here.',
    et: 'Choose a project category here.',
    lt: 'Choose a project category here.',
    ph: 'Choose a project category here.',
    cn: 'Choose a project category here.',
  },
  projectsContent4: {
    en: 'Press on any project to find out more about it.',
    ja: 'Press on any project to find out more about it.',
    et: 'Press on any project to find out more about it.',
    lt: 'Press on any project to find out more about it.',
    ph: 'Press on any project to find out more about it.',
    cn: 'Press on any project to find out more about it.',
  },
  projectContent1: {
    en: 'You can see details about the project in this section.',
    ja: 'You can see details about the project in this section.',
    et: 'You can see details about the project in this section.',
    lt: 'You can see details about the project in this section.',
    ph: 'You can see details about the project in this section.',
    cn: 'You can see details about the project in this section.',
  },
  projectContent2: {
    en: 'You can also comment on it and share your thoughts. Remember to be nice.',
    ja: 'You can also comment on it and share your thoughts. Remember to be nice.',
    et: 'You can also comment on it and share your thoughts. Remember to be nice.',
    lt: 'You can also comment on it and share your thoughts. Remember to be nice.',
    ph: 'You can also comment on it and share your thoughts. Remember to be nice.',
    cn: 'You can also comment on it and share your thoughts. Remember to be nice.',
  },
  projectContent3: {
    en: 'Lastly, you can chat with the creator of this project!',
    ja: 'Lastly, you can chat with the creator of this project!',
    et: 'Lastly, you can chat with the creator of this project!',
    lt: 'Lastly, you can chat with the creator of this project!',
    ph: 'Lastly, you can chat with the creator of this project!',
    cn: 'Lastly, you can chat with the creator of this project!',
  },
  projectFormContent1: {
    en: 'You can mark your project as Work-In-Progress if it is not finished, or Completed if it is. '
    + 'Only Completed projects can earn badges.',
    ja: 'You can mark your project as Work-In-Progress if it is not finished, or Completed if it is. '
    + 'Only Completed projects can earn badges.',
    et: 'You can mark your project as Work-In-Progress if it is not finished, or Completed if it is. '
    + 'Only Completed projects can earn badges.',
    lt: 'You can mark your project as Work-In-Progress if it is not finished, or Completed if it is. '
    + 'Only Completed projects can earn badges.',
    ph: 'You can mark your project as Work-In-Progress if it is not finished, or Completed if it is. '
    + 'Only Completed projects can earn badges.',
    cn: 'You can mark your project as Work-In-Progress if it is not finished, or Completed if it is. '
    + 'Only Completed projects can earn badges.',
  },
  projectFormContent2: {
    en: 'Try selecting Completed!',
    ja: 'Try selecting Completed!',
    et: 'Try selecting Completed!',
    lt: 'Try selecting Completed!',
    ph: 'Try selecting Completed!',
    cn: 'Try selecting Completed!',
  },
  projectFormContent3: {
    en: 'You can select the badges that you want to earn from this project. '
    + 'If your project is approved by our VIVIBOOM staff, you will earn those badges!',
    ja: 'You can select the badges that you want to earn from this project. '
    + 'If your project is approved by our VIVIBOOM staff, you will earn those badges!',
    et: 'You can select the badges that you want to earn from this project. '
    + 'If your project is approved by our VIVIBOOM staff, you will earn those badges!',
    lt: 'You can select the badges that you want to earn from this project. '
    + 'If your project is approved by our VIVIBOOM staff, you will earn those badges!',
    ph: 'You can select the badges that you want to earn from this project. '
    + 'If your project is approved by our VIVIBOOM staff, you will earn those badges!',
    cn: 'You can select the badges that you want to earn from this project. '
    + 'If your project is approved by our VIVIBOOM staff, you will earn those badges!',
  },
  projectFormContent4: {
    en: 'Remember that to post a project, you must include at least a title and an image!',
    ja: 'Remember that to post a project, you must include at least a title and an image!',
    et: 'Remember that to post a project, you must include at least a title and an image!',
    lt: 'Remember that to post a project, you must include at least a title and an image!',
    ph: 'Remember that to post a project, you must include at least a title and an image!',
    cn: 'Remember that to post a project, you must include at least a title and an image!',
  },
  VIVINAUTS: {
    en: 'VIVINAUTS',
    ja: 'VIVINAUTS',
    et: 'VIVINAUTS',
    lt: 'VIVINAUTS',
    ph: 'VIVINAUTS',
    cn: 'VIVINAUTS',
  },
  Creators: {
    en: 'Creators',
    ja: 'クリエイター',
    et: 'Loojad',
    lt: 'Kūrėjai',
    ph: 'Creators',
    ch: '创作者',
  },
  membersContent1: {
    en: 'Here, you can find all your fellow Creators.',
    ja: 'Here, you can find all your fellow Creators.',
    et: 'Here, you can find all your fellow Creators.',
    lt: 'Here, you can find all your fellow Creators.',
    ph: 'Here, you can find all your fellow Creators.',
    cn: 'Here, you can find all your fellow Creators.',
  },
  membersContent2: {
    en: 'Try selecting one of them!',
    ja: 'Try selecting one of them!',
    et: 'Try selecting one of them!',
    lt: 'Try selecting one of them!',
    ph: 'Try selecting one of them!',
    cn: 'Try selecting one of them!',
  },
  memberContent1: {
    en: "You can see this Creators's projects and badges",
    ja: "You can see this Creators's projects and badges",
    et: "You can see this Creators's projects and badges",
    lt: "You can see this Creators's projects and badges",
    ph: "You can see this Creators's projects and badges",
    cn: "You can see this Creators's projects and badges",
  },
  memberContent2: {
    en: 'You can also send them a chat message!',
    ja: 'You can also send them a chat message!',
    et: 'You can also send them a chat message!',
    lt: 'You can also send them a chat message!',
    ph: 'You can also send them a chat message!',
    cn: 'You can also send them a chat message!',
  },
  Bookings: {
    en: 'Bookings',
    ja: 'Bookings',
    et: 'Bookings',
    lt: 'Bookings',
    ph: 'Bookings',
    cn: 'Bookings',
  },
  bookingsContent1: {
    en: 'Here, you can book a session to join an upcoming event or workshop.',
    ja: 'Here, you can book a session to join an upcoming event or workshop.',
    et: 'Here, you can book a session to join an upcoming event or workshop.',
    lt: 'Here, you can book a session to join an upcoming event or workshop.',
    ph: 'Here, you can book a session to join an upcoming event or workshop.',
    cn: 'Here, you can book a session to join an upcoming event or workshop.',
  },
  Events: {
    en: 'Events',
    ja: 'Events',
    et: 'Events',
    lt: 'Events',
    ph: 'Events',
    cn: 'Events',
  },
  eventsContent1: {
    en: 'Here, you can see both our past and current exciting events.',
    ja: 'Here, you can see both our past and current exciting events.',
    et: 'Here, you can see both our past and current exciting events.',
    lt: 'Here, you can see both our past and current exciting events.',
    ph: 'Here, you can see both our past and current exciting events.',
    cn: 'Here, you can see both our past and current exciting events.',
  },
};

export default {
  language: {
    en: 'English',
    ja: '日本語',
    et: 'Estonian',
    lt: 'Lietuvių',
    ph: 'Tagalog',
    ch: '华语',
  },
  'Description saved!': {
    en: 'Description saved!',
    ja: '説明が保存しました！',
    et: 'Kirjeldus salvestatud!',
    lt: 'Apibūdinimas išsaugotas',
    ph: 'Description saved!',
    ch: 'Description saved!',
  },
  'Add a short description about yourself!': {
    en: 'Add a short description about yourself!',
    ja: '自己紹介をしてください！',
    et: 'Lisa enda lühikirjeldus',
    lt: 'Pridėk porą žodžių apie save',
    ph: 'Add a short description about yourself!',
    ch: 'Add a short description about yourself!',
  },
  Save: {
    en: 'Save',
    ja: '保存',
    et: 'Salvesta',
    lt: 'Išsaugoti ',
    ph: 'Save',
    ch: 'Save',
  },
  'Cover Image Saved!': {
    en: 'Cover Image Saved!',
    ja: 'カバー画像が保存しました！',
    et: 'Päisepilt salvestatud!',
    lt: 'Viršelio vaizdas išsaugotas',
    ph: 'Cover Image Saved!',
    ch: 'Cover Image Saved!',
  },
  'Add a background photo!': {
    en: 'Add a background photo!',
    ja: '背景画像を追加',
    et: 'Lisa taustafoto!',
    lt: 'Pridėk fono nuotrauką',
    ph: 'Add a background photo!',
    ch: 'Add a background photo!',
  },
  'Add Background': {
    en: 'Add Background',
    ja: '背景を追加',
    et: 'Lisa taust',
    lt: 'Pridėk foną',
    ph: 'Add Background',
    ch: 'Add Background',
  },
  Hello: {
    en: 'Hello',
    ja: 'こんにちは',
    et: 'Tere',
    lt: 'Labas',
    ph: 'Hello',
    ch: 'Hello',
  },
  'WELCOME TO': {
    en: 'WELCOME TO',
    ja: 'ようこそ',
    et: 'TERE TULEMAST',
    lt: 'ATVYKAI Į',
    ph: 'WELCOME TO',
    ch: 'WELCOME TO',
  },
  VIVIBOOM: {
    en: 'VIVIBOOM',
    ja: 'VIVIBOOMへ',
    et: 'VIVIBOOMI',
    lt: 'VIVIBOOM',
    ph: 'VIVIBOOM',
    ch: 'VIVIBOOM',
  },
  'WELCOME TO VIVIBOOM!': {
    en: 'WELCOME TO VIVIBOOM!',
    ja: 'VIVIBOOMへようこそ！',
    et: 'TERE TULEMAST VIVIBOOMI!',
    lt: 'ATVYKAI Į VIVIBOOM!',
    ph: 'WELCOME TO VIVIBOOM!',
    ch: 'WELCOME TO VIVIBOOM!',
  },
  'No Curriculum. No Fees. No Limits.': {
    en: 'No Curriculum. No Fees. No Limits.',
    ja: 'No Curriculum. No Fees. No Limits.',
    et: 'Õppekava vaba. Tasuta. Piirideta.',
    lt: 'Jokių planų, jokių ribų, jokių stabdžių. ',
    ph: 'No Curriculum. No Fees. No Limits.',
    ch: 'No Curriculum. No Fees. No Limits.',
  },
  'Profile Image Saved!': {
    en: 'Profile Image Saved!',
    ja: 'プロフィール画像が保存しました',
    et: 'Profiilipilt salvestatud!',
    lt: 'Profilio nuotrauka išsaugota',
    ph: 'Profile Image Saved!',
    ch: 'Profile Image Saved!',
  },
  'Add a profile picture!': {
    en: 'Add a profile picture!',
    ja: 'プロフィール画像を追加',
    et: 'Lisa profiilipilt!',
    lt: 'Pridėk profilio nuotrauką',
    ph: 'Add a profile picture!',
    ch: 'Add a profile picture!',
  },
  'Upload photo or drag a photo here': {
    en: 'Upload photo or drag a photo here',
    ja: '画像をアップロードするか、またはここへドロップ',
    et: 'Laadi või lohista pilt siia',
    lt: 'Įkelk nuotrauką arba atitempk ją čia',
    ph: 'Upload photo or drag a photo here',
    ch: 'Upload photo or drag a photo here',
  },
  'Add Profile Image': {
    en: 'Add Profile Image',
    ja: 'プロフィール画像を追加',
    et: 'Lisa profiilipilt',
    lt: 'Pridėti profilio nuotrauką',
    ph: 'Add Profile Image',
    ch: 'Add Profile Image',
  },
  'Some of the cool stuff you can do!': {
    en: 'Some of the cool stuff you can do!',
    ja: 'ここでこんなことができます！',
    et: 'Ägedad asjad, mida saad teha!',
    lt: 'Štai ką linksmo čia galima nuveikti',
    ph: 'Some of the cool stuff you can do!',
    ch: 'Some of the cool stuff you can do!',
  },
  'Share your projects!': {
    en: 'Share your projects!',
    ja: 'プロジェクトをみんなに共有',
    et: 'Jaga oma projekte!',
    lt: 'Pasidalink savo projektais!',
    ph: 'Share your projects!',
    ch: 'Share your projects!',
  },
  'Earn shiny badges!': {
    en: 'Earn shiny badges!',
    ja: 'スキルバッジを獲得',
    et: 'Teeni säravaid tunnusmärke!',
    lt: 'Užsitarnauk smagių ženkliukų!',
    ph: 'Earn shiny badges!',
    ch: 'Earn shiny badges!',
  },
  'Pick up new skills!': {
    en: 'Pick up new skills!',
    ja: '新しいスキルを学ぶ',
    et: 'Omanda uusi oskusi',
    lt: 'Išmok naujų dalykų!',
    ph: 'Pick up new skills!',
    ch: 'Pick up new skills!',
  },
  Back: {
    en: 'Back',
    ja: '前へ',
    et: 'Tagasi',
    lt: 'Atgal',
    ph: 'Back',
    ch: 'Back',
  },
  Next: {
    en: 'Next',
    ja: '次へ',
    et: 'Edasi',
    lt: 'Toliau',
    ph: 'Next',
    ch: 'Next',
  },
  'Done!': {
    en: 'Done!',
    ja: '完了！',
    et: 'Valmis!',
    lt: 'Baigta!',
    ph: 'Done!',
    ch: 'Done!',
  },
  subtitleNonRoot: {
    en: 'The super secret VIVITA-{{name}} portal to supercharge your skills!',
    ja: 'VIVITA-{{name}}メンバーの秘密サイトでスキルを磨こう！',
    et: 'Supersalajane VIVITA-{{name}} liikmeportaal oma superoskuste arendamiseks',
    lt: 'Ypač slaptas VIVITA-{{name}} narių tinklapis, skirtas dalintis patirtimi ir smagiai įkvėpti vieniems kitus.',
    ph: 'The super secret VIVITA-{{name}} portal to supercharge your skills!',
    ch: 'The super secret VIVITA-{{name}} portal to supercharge your skills!',
  },
  Login: {
    en: 'Login',
    ja: 'ログイン',
    et: 'Logi sisse',
    lt: 'Prisijungti',
    ph: 'Login',
    ch: 'Login',
  },
  'Join VIVITA': {
    en: 'Join VIVITA',
    ja: 'VIVITAに加入する',
    et: 'Liitu VIVITAga',
    lt: 'Prisijungti prie VIVITA',
    ph: 'Join VIVITA',
    ch: 'Join VIVITA',
  },
  searchInstitutionInput: {
    en: 'Search school/institution',
    ja: '学校/教育機関を探す',
    et: 'Otsige kooli/asutust',
    lt: 'Ieškoti mokyklos/įstaigos',
    ph: 'Search school/institution',
    ch: '搜寻学校/机构',
  },
  searchInstitution: {
    en: 'Search for your school/institution here and tap the link to access the login page.',
    ja: 'ここで学校/教育機関を検索し、リンクをタップしてログイン ページにアクセスします。',
    et: 'Otsige siit oma kooli/asutust ja puudutage sisselogimislehele pääsemiseks linki.',
    lt: 'Čia ieškokite savo mokyklos / institucijos ir bakstelėkite nuorodą, kad pasiektumėte prisijungimo puslapį.',
    ph: 'Search for your school/institution here and tap the link to access the login page.',
    ch: '在此处搜索您的学校/机构，然后点击链接访问登录页面。',
  },
  'Portal Login': {
    en: 'Portal Login',
    ja: 'サイトへログイン',
    et: 'Logimine',
    lt: 'Tinklapio prisijungimas',
    ph: 'Portal Login',
    ch: 'Portal Login',
  },
  signInAs: {
    en: 'Sign in to the portal as',
    ja: 'どのようにしてログインしたいですか？',
    et: 'Logige portaali sisse kasutajana',
    lt: 'Prisijunkite prie portalo kaip',
    ph: 'Sign in to the portal as',
    ch: '你想如何登陆？',
  },
  'Become a VIVITA Member': {
    en: 'Become a VIVITA Member',
    ja: 'VIVITAメンバーになる',
    et: 'Saa VIVITA liikmeks',
    lt: 'Tapk VIVITA nariu',
    ph: 'Become a VIVITA Member',
    ch: 'Become a VIVITA Member',
  },
  descFirstLine: {
    en: 'Located in {{countryCount}} countries worldwide, VIVITA is an international organization on a mission to equip youths with 21st century skills, providing them with the physical and digital environments for fearless exploration.',
    ja: 'Located in {{countryCount}} countries worldwide, VIVITA is an international organization on a mission to equip youths with 21st century skills, providing them with the physical and digital environments for fearless exploration.',
    et: 'VIVITA on rahvusvaheline loovkiirendite võrgustik, mis paikneb {{countryCount}} riigis. Meie eesmärgiks on anda noortele 21. sajandi oskused ning julgus suurelt unistada ja avastada nii digi- kui füüsilistes keskkondades.',
    lt: 'VIVITA veikia iš viso {{countryCount}} šalyse visame pasaulyje, ir suteikia jauniems kūrėjams šiuolakines technologijas ir saugias sąlygas laisvai kurti, eksperimentuoti, tobulėti bei įgyvendinti savo idėjas. ',
    ph: 'Located in {{countryCount}} countries worldwide, VIVITA is an international organization on a mission to equip youths with 21st century skills, providing them with the physical and digital environments for fearless exploration.',
    ch: 'Located in {{countryCount}} countries worldwide, VIVITA is an international organization on a mission to equip youths with 21st century skills, providing them with the physical and digital environments for fearless exploration.',
  },
  descSecondLine: {
    en: 'With our no curriculum, no fees and no limits philosophy, we support and help transform youth’s ideas into real skills through the expertise of our global network of experts and changemakers.',
    ja: 'With our no curriculum, no fees and no limits philosophy, we support and help transform youth’s ideas into real skills through the expertise of our global network of experts and changemakers.',
    et: 'VIVITAs pole õppekava, osalustasu ega piiranguid. Me aitame su vahvad ideed teoks muuta, kaasame sinu idee teostusse oma globaalse ekspertide ja maailmamuutjate võrgustiku.',
    lt: 'Kadangi neturime planų, ribų ar stabdžių, padedame jauniesiems kūrėjams paversti savo sumanymus tikrove ir patiems išsiugdyti jiems reikalingų įgūdžių. Visa tai darome su savo tarptautinio smalsių mentorių bei kūrėjų tinklo pagalba. ',
    ph: 'With our no curriculum, no fees and no limits philosophy, we support and help transform youth’s ideas into real skills through the expertise of our global network of experts and changemakers.',
    ch: 'With our no curriculum, no fees and no limits philosophy, we support and help transform youth’s ideas into real skills through the expertise of our global network of experts and changemakers.',
  },
  'Find Out More': {
    en: 'Find Out More',
    ja: 'もっと詳しく',
    et: 'Uuri edasi',
    lt: 'Sužinok daugiau',
    ph: 'Find Out More',
    ch: 'Find Out More',
  },
  'Check out our public events!': {
    en: 'Check out our public events!',
    ja: '公開イベントをチェックしてください!',
    et: 'Vaata meie avalikke üritusi!',
    lt: 'Peržiūrėkite mūsų viešus renginius!',
    ph: 'Check out our public events!',
    ch: '查看我们最近的活动！',
  },
  'Create an account as': {
    en: 'Create an account as',
    ja: 'あなたは...',
    et: 'Loo konto kui',
    lt: 'Sukurti paskyrą kaip',
    ph: 'Create an account as',
    ch: '你是...',
  },
  Member: {
    en: 'Member',
    ja: 'メンバー',
    et: 'Liige',
    lt: 'Narys',
    ph: 'Member',
    ch: '会员',
  },
  Student: {
    en: 'Student',
    ja: '学生',
    et: 'Õpilane',
    lt: 'Studentas',
    ph: 'Student',
    ch: '学生',
  },
  'Aready have a Viviboom account?': {
    en: 'Aready have a Viviboom account?',
    ja: 'Viviboom アカウントをお持ちですか?',
    et: 'Kas teil on Viviboomi konto?',
    lt: 'Ar jau turite Viviboom paskyrą?',
    ph: 'Aready have a Viviboom account?',
    ch: '已经有 Viviboom 帐户了吗？',
  },
  'Log in here': {
    en: 'Log in here',
    ja: 'ここでログイン',
    et: 'Logi sisse siit',
    lt: 'Prisijunkite čia',
    ph: 'Log in here',
    ch: '在此登录',
  },
  login: {
    en: 'Login',
    ja: 'ログイン',
    et: 'Logi sisse',
    lt: 'Prisijungti',
    ph: 'Login',
    ch: '登录',
  },
  'Set Up Account': {
    en: 'Set Up Account',
    ja: 'Set Up Account',
    et: 'Set Up Account',
    lt: 'Set Up Account',
    ph: 'Set Up Account',
    ch: 'Set Up Account',
  },
  Username: {
    en: 'Username',
    ja: 'Username',
    et: 'Username',
    lt: 'Username',
    ph: 'Username',
    ch: 'Username',
  },
  Password: {
    en: 'Password',
    ja: 'Password',
    et: 'Password',
    lt: 'Password',
    ph: 'Password',
    ch: 'Password',
  },
  'Confirm Password': {
    en: 'Confirm Password',
    ja: 'Confirm Password',
    et: 'Confirm Password',
    lt: 'Confirm Password',
    ph: 'Confirm Password',
    ch: 'Confirm Password',
  },
  'Personal Details': {
    en: 'Personal Details',
    ja: 'Personal Details',
    et: 'Personal Details',
    lt: 'Personal Details',
    ph: 'Personal Details',
    ch: 'Personal Details',
  },
  'Given Name': {
    en: 'Given Name',
    ja: 'Given Name',
    et: 'Given Name',
    lt: 'Given Name',
    ph: 'Given Name',
    ch: 'Given Name',
  },
  'Family Name': {
    en: 'Family Name',
    ja: 'Family Name',
    et: 'Family Name',
    lt: 'Family Name',
    ph: 'Family Name',
    ch: 'Family Name',
  },
  Email: {
    en: 'Email',
    ja: 'Email',
    et: 'Email',
    lt: 'Email',
    ph: 'Email',
    ch: 'Email',
  },
  'Institution Details': {
    en: 'Institution Details',
    ja: 'Institution Details',
    et: 'Institution Details',
    lt: 'Institution Details',
    ph: 'Institution Details',
    ch: 'Institution Details',
  },
  'Institution Name': {
    en: 'Institution Name',
    ja: 'Institution Name',
    et: 'Institution Name',
    lt: 'Institution Name',
    ph: 'Institution Name',
    ch: 'Institution Name',
  },
  'Password need to be at least 8 character long': {
    en: 'Password need to be at least 8 character long',
    ja: 'パスワードは8文字以上にする必要があります',
    et: 'Parool peab olema vähemalt 8 tähemärki pikk',
    lt: 'Slaptažodis turi būti bent 8 simbolių ilgio',
    ph: 'Password need to be al least 8 character long',
    ch: '密码长度必须至少为 8 个字符',
  },
  'Passwords need to be the same': {
    en: 'Passwords need to be the same',
    ja: 'Passwords need to be the same',
    et: 'Passwords need to be the same',
    lt: 'Passwords need to be the same',
    ph: 'Passwords need to be the same',
    ch: 'Passwords need to be the same',
  },
  required: {
    en: 'Please enter {{name}}',
    ja: '{{name}}を入力してください',
    et: 'Palun sisestage {{name}}',
    lt: 'Įveskite {{name}}',
    ph: 'Please enter {{name}}',
    ch: '请输入{{name}}',
  },
  username: {
    en: 'Username',
    ja: 'ユーザー名',
    et: 'Kasutajanimi',
    lt: 'Vardas ',
    ph: 'Username',
    ch: '用户名称',
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-mail',
    lt: 'Emailas',
    ph: 'Email',
    ch: '电邮地址',
  },
  password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Salasõna',
    lt: 'Slaptažodis',
    ph: 'Password',
    ch: '密码',
  },
  'Invalid email': {
    en: 'Invalid email',
    ja: 'Invalid email',
    et: 'Invalid email',
    lt: 'Invalid email',
    ph: 'Invalid email',
    ch: 'Invalid email',
  },
  invalidUsername: {
    en: 'Please remove any special characters other than . and _',
    ja: 'Please remove any special characters other than . and _',
    et: 'Please remove any special characters other than . and _',
    lt: 'Please remove any special characters other than . and _',
    ph: 'Please remove any special characters other than . and _',
    ch: 'Please remove any special characters other than . and _',
  },
  givenName: {
    en: 'Given Name',
    ja: '名',
    et: 'Antud nimi',
    lt: 'Duotas vardas',
    ph: 'Given Name',
    ch: '名',
  },
  familyName: {
    en: 'Family Name',
    ja: '姓',
    et: 'Perekonnanimi',
    lt: 'Pavardė',
    ph: 'Family Name',
    ch: '姓',
  },
  'name of institution': {
    en: 'name of institution',
    ja: 'name of institution',
    et: 'name of institution',
    lt: 'name of institution',
    ph: 'name of institution',
    ch: 'name of institution',
  },
  'institution country': {
    en: 'institution country',
    ja: 'institution country',
    et: 'institution country',
    lt: 'institution country',
    ph: 'institution country',
    ch: 'institution country',
  },
  'Institution Country': {
    en: 'Institution Country',
    ja: 'Institution Country',
    et: 'Institution Country',
    lt: 'Institution Country',
    ph: 'Institution Country',
    ch: 'Institution Country',
  },
  'Branch Name': {
    en: 'Branch Name',
    ja: 'Branch Name',
    et: 'Branch Name',
    lt: 'Branch Name',
    ph: 'Branch Name',
    ch: 'Branch Name',
  },
  'All Done!': {
    en: 'All Done!',
    ja: 'All Done!',
    et: 'All Done!',
    lt: 'All Done!',
    ph: 'All Done!',
    ch: 'All Done!',
  },
  'The institution name is already taken': {
    en: 'The institution name is already taken',
    ja: 'The institution name is already taken',
    et: 'The institution name is already taken',
    lt: 'The institution name is already taken',
    ph: 'The institution name is already taken',
    ch: 'The institution name is already taken',
  },
  'The sign-up code is already taken': {
    en: 'The sign-up code is already taken',
    ja: 'The sign-up code is already taken',
    et: 'The sign-up code is already taken',
    lt: 'The sign-up code is already taken',
    ph: 'The sign-up code is already taken',
    ch: 'The sign-up code is already taken',
  },
  'Invalid country ISO code': {
    en: 'Invalid country ISO code',
    ja: 'Invalid country ISO code',
    et: 'Invalid country ISO code',
    lt: 'Invalid country ISO code',
    ph: 'Invalid country ISO code',
    ch: 'Invalid country ISO code',
  },
  'country timezone': {
    en: 'country timezone',
    ja: 'country timezone',
    et: 'country timezone',
    lt: 'country timezone',
    ph: 'country timezone',
    ch: 'country timezone',
  },
  'branch name': {
    en: 'branch name',
    ja: 'branch name',
    et: 'branch name',
    lt: 'branch name',
    ph: 'branch name',
    ch: 'branch name',
  },
  'IANA Timezone (Continent/Region)': {
    en: 'IANA Timezone (Continent/Region)',
    ja: 'IANA Timezone (Continent/Region)',
    et: 'IANA Timezone (Continent/Region)',
    lt: 'IANA Timezone (Continent/Region)',
    ph: 'IANA Timezone (Continent/Region)',
    ch: 'IANA Timezone (Continent/Region)',
  },
  'Institution name need to be at least 3 characters long': {
    en: 'Institution name need to be at least 3 characters long',
    ja: 'Institution name need to be at least 3 characters long',
    et: 'Institution name need to be at least 3 characters long',
    lt: 'Institution name need to be at least 3 characters long',
    ph: 'Institution name need to be at least 3 characters long',
    ch: 'Institution name need to be at least 3 characters long',
  },
  'Sign Up Code (optional)': {
    en: 'Sign Up Code (optional)',
    ja: 'Sign Up Code (optional)',
    et: 'Sign Up Code (optional)',
    lt: 'Sign Up Code (optional)',
    ph: 'Sign Up Code (optional)',
    ch: 'Sign Up Code (optional)',
  },
  'Sign up code needs to be 6 characters long': {
    en: 'Sign up code needs to be 6 characters long',
    ja: 'Sign up code needs to be 6 characters long',
    et: 'Sign up code needs to be 6 characters long',
    lt: 'Sign up code needs to be 6 characters long',
    ph: 'Sign up code needs to be 6 characters long',
    ch: 'Sign up code needs to be 6 characters long',
  },
  'Thank you for signing up!': {
    en: 'Thank you for signing up!',
    ja: 'Thank you for signing up!',
    et: 'Thank you for signing up!',
    lt: 'Thank you for signing up!',
    ph: 'Thank you for signing up!',
    ch: 'Thank you for signing up!',
  },
  "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.": {
    en: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    ja: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    et: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    lt: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    ph: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
    ch: "Please open the email we've sent you and confirm our email address to start using VIVIBOOM.",
  },
  "Don't forget our email may be hiding in your spam folder!": {
    en: "Don't forget our email may be hiding in your spam folder!",
    ja: "Don't forget our email may be hiding in your spam folder!",
    et: "Don't forget our email may be hiding in your spam folder!",
    lt: "Don't forget our email may be hiding in your spam folder!",
    ph: "Don't forget our email may be hiding in your spam folder!",
    ch: "Don't forget our email may be hiding in your spam folder!",
  },
  signUpCodeDescription: {
    en: 'Your students need this code to sign up Viviboom. If it is left empty, we will generate a random code for you',
    ja: 'Your students need this code to sign up Viviboom. If it is left empty, we will generate a random code for you',
    et: 'Your students need this code to sign up Viviboom. If it is left empty, we will generate a random code for you',
    lt: 'Your students need this code to sign up Viviboom. If it is left empty, we will generate a random code for you',
    ph: 'Your students need this code to sign up Viviboom. If it is left empty, we will generate a random code for you',
    ch: 'Your students need this code to sign up Viviboom. If it is left empty, we will generate a random code for you',
  },
  'New to Viviboom?': {
    en: 'New to Viviboom?',
    ja: 'New to Viviboom?',
    et: 'New to Viviboom?',
    lt: 'New to Viviboom? ',
    ph: 'New to Viviboom?',
    ch: 'New to Viviboom?',
  },
  'Sign Up': {
    en: 'Sign Up',
    ja: 'Sign Up',
    et: 'Sign Up',
    lt: 'Sign Up ',
    ph: 'Sign Up',
    ch: 'Sign Up',
  },
  usernameLength: {
    en: 'Username length must be between 5 to 18 characters',
    ja: 'Username length must be between 5 to 18 characters',
    et: 'Username length must be between 5 to 18 characters',
    lt: 'Username length must be between 5 to 18 characters',
    ph: 'Username length must be between 5 to 18 characters',
    ch: 'Username length must be between 5 to 18 characters',
  },
};

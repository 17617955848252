export default {
  consentNotification: {
    en: 'You must consent to our use of cookies to access VIVIBOOM',
    ja: 'VIVIBOOM にアクセスするには、Cookie の使用に同意する必要があります',
    et: 'VIVIBOOMi kasutamiseks pead nõustuma küpsiste kasutamise poliitikaga',
    lt: 'Prieš naudojant VIVIBOOM, reikia sutikti su slapukų taisyklėmis',
    ph: 'You must consent to our use of cookies to access VIVIBOOM',
    ch: '您必须同意我们使用 cookie 才能访问 VIVIBOOM',
  },
  consent: {
    en: 'I consent to the <1>use of cookies</1>',
    ja: '<1>Cookie の使用</1>に同意します',
    et: 'Nõustun <1>küpsiste kasutamise poliitikaga</1>',
    lt: 'Sutinku su <1>slapukų taisyklėmis</1>',
    ph: 'I consent to the <1>use of cookies</1>',
    ch: '我同意<1>使用 cookie</1>',
  },
  serviceTerms: {
    en: 'Terms of Service',
    ja: '規約と条件',
    et: 'Kasutajatingimused',
    lt: 'Paslaugų teikimo sąlygos',
    ph: 'Terms of Service',
    ch: '条款和条件',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    ja: 'Privacy Policy',
    et: 'Privaatsuspoliitika',
    lt: 'Privatumo politika',
    ph: 'Privacy Policy',
    ch: 'Privacy Policy',
  },
  login: {
    en: 'Login',
    ja: 'ログイン',
    et: 'Logi sisse',
    lt: 'Prisijungti',
    ph: 'Login',
    ch: '登录',
  },
  createAccount: {
    en: 'Create Account',
    ja: 'アカウント登録',
    et: 'Loo konto',
    lt: 'Susikurti paskyrą',
    ph: 'Create Account',
    ch: '注册户口',
  },
  usernameOrEmail: {
    en: 'Username or Email',
    ja: 'ユーザー名またはメール',
    et: 'Kasutajanimi või e-mail',
    lt: 'Vardas arba emailas',
    ph: 'Username or Email',
    ch: '用户名称或电邮地址',
  },
  username: {
    en: 'Username',
    ja: 'ユーザー名',
    et: 'Kasutajanimi',
    lt: 'Vardas ',
    ph: 'Username',
    ch: '用户名称',
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-mail',
    lt: 'Emailas',
    ph: 'Email',
    ch: '电邮地址',
  },
  signUpDisclaimer: {
    en: 'By clicking "Create Account" you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>.',
    ja: '[アカウント登録]をクリックすると、<0>利用規約</0>と<1>プライバシーポリシー</1>に同意したことになります。',
    et: 'Vajutades "Loo konto" nõustud sa <0>Kasutamise tingimuste</0> ja <1>Privaatsuspoliitikaga</1>.',
    lt: 'Spaudžiant "Sukurti paskyra" sutinki su <0>Paslaugų teikimo sąlygomis</0> ir <1>Privatumo politika</1>.',
    ph: 'By clicking "Create Account" you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>.',
    ch: '单击‘创建户口’,您同意我们的<0>服务条款</0>和<1>隐私政策</1>',
  },
  password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Salasõna',
    lt: 'Slaptažodis',
    ph: 'Password',
    ch: '密码',
  },
  troubleLoggingIn: {
    en: 'Forgot your password?',
    ja: 'パスワードをお忘れですか？',
    et: 'Unustasid salasõna?',
    lt: 'Pamiršai slaptažodį?',
    ph: 'Forgot your password?',
    ch: '忘记密码了吗？',
  },
  noAccount: {
    en: "Don't have an account yet?",
    ja: 'まだアカウントをお持ちでない場合は、',
    et: 'Kas teil pole veel kontot?',
    lt: 'Dar neturite paskyros?',
    ph: "Don't have an account yet?",
    ch: '还没有帐户？',
  },
  haveCode: {
    en: 'Got a Sign-Up Code?',
    ja: 'サインアップ コードをお持ちですか?',
    et: 'Kas teil on registreerumiskood?',
    lt: 'Turite registracijos kodą?',
    ph: 'Got a Sign-Up Code?',
    ch: '有注册码吗？',
  },
  or: {
    en: 'or',
    ja: 'または',
    et: 'või',
    lt: 'ar',
    ph: 'or',
    ch: '或',
  },
  logInSuccess: {
    en: 'Success! Logging you in 😎',
    ja: '完了！ ログインしています 😎',
    et: 'Valmis! Login sind sisse 😎',
    lt: 'Pavyko! Prijungiame tave 😎',
    ph: 'Success! Logging you in 😎',
    ch: '成功！正在进行登入。😎',
  },
  pleaseVerifyYourEmail: {
    en: 'Please verify your email.',
    ja: 'メールアドレスを確認してください。',
    et: 'Kontrolli oma e-maili',
    lt: 'Patvirtink savo emailą',
    ph: 'Please verify your email.',
    ch: '请确认你的电邮地址。',
  },
  pleaseVerifyYourEmailLinkText: {
    en: 'To request a new email verification code, click here',
    ja: '新しいメール確認コードをリクエストするには、ここをクリックしてください',
    et: 'Uue kinnituskoodi saamiseks e-mailiga vajuta siia',
    lt: 'Reikia naujo patvirtinimo kodo? Spausk čia',
    ph: 'To request a new email verification code, click here',
    ch: '如果你要请求新的电子邮件验证代码, 请单击此处。',
  },
  codeDescription: {
    en: 'You will receive a sign-up code from your teacher to create your account in Viviboom.',
    ja: 'Viviboom でアカウントを作成するためのサインアップ コードを教師から受け取ります。',
    et: 'Saate oma õpetajalt registreerumiskoodi Viviboomi konto loomiseks.',
    lt: 'Iš savo mokytojo gausite registracijos kodą, kad galėtumėte sukurti paskyrą Viviboom.',
    ph: 'You will receive a sign-up code from your teacher to create your account in Viviboom.',
    ch: '您将收到老师发来的注册码，用于在 Viviboom 中创建帐户。',
  },
  Continue: {
    en: 'Continue',
    ja: '続く',
    et: 'Jätka',
    lt: 'Tęsti',
    ph: 'Continue',
    ch: '继续',
  },
  invalidCode: {
    en: 'The provided class code is not valid. Kindly verify with your teacher and attempt using a different class code.',
    ja: '指定されたクラス コードは無効です。 先生に確認して、別のクラス コードを使用してみてください。',
    et: 'Esitatud klassi kood ei kehti. Kontrollige oma õpetajaga ja proovige kasutada teist klassi koodi.',
    lt: 'Pateiktas klasės kodas negalioja. Patvirtinkite su mokytoju ir pabandykite naudoti kitą klasės kodą.',
    ph: 'The provided class code is not valid. Kindly verify with your teacher and attempt using a different class code.',
    ch: '提供的代码无效。 请与您的老师核实并尝试使用不同的班级代码。',
  },
  givenName: {
    en: 'Given Name',
    ja: '名',
    et: 'Antud nimi',
    lt: 'Duotas vardas',
    ph: 'Given Name',
    ch: '名',
  },
  familyName: {
    en: 'Family Name',
    ja: '姓',
    et: 'Perekonnanimi',
    lt: 'Pavardė',
    ph: 'Family Name',
    ch: '姓',
  },
  signUpFor: {
    en: 'Signing up for {{name}}',
    ja: '{{name}} @ VIVIBOOM に登録しています',
    et: 'Registreerumine kasutajaks {{name}}',
    lt: 'Prisiregistravimas prie {{name}}',
    ph: 'Signing up for {{name}}',
    ch: '注册 {{name}} @ VIVIBOOM',
  },
  haveAccount: {
    en: 'Already have a Viviboom account?',
    ja: 'Viviboom アカウントをお持ちですか?',
    et: 'Kas teil on Viviboomi konto?',
    lt: 'Ar jau turite Viviboom paskyrą?',
    ph: 'Already have a Viviboom account?',
    ch: '已经有 Viviboom 帐户了吗？',
  },
  required: {
    en: 'Please enter {{name}}',
    ja: '{{name}}を入力してください',
    et: 'Palun sisestage {{name}}',
    lt: 'Įveskite {{name}}',
    ph: 'Please enter {{name}}',
    ch: '请输入{{name}}',
  },
  invalidUsername: {
    en: 'Please remove any special characters in your username',
    ja: 'ユーザー名に含まれる特殊文字を削除してください',
    et: 'Palun eemaldage oma kasutajanimest kõik erimärgid',
    lt: 'Pašalinkite visus specialiuosius simbolius iš savo vartotojo vardo',
    ph: 'Please remove any special characters in your username',
    ch: '请删除您用户名中的所有特殊字符',
  },
  invalidPassword: {
    en: 'Password need to be al least 8 character long',
    ja: 'パスワードは8文字以上にする必要があります',
    et: 'Parool peab olema vähemalt 8 tähemärki pikk',
    lt: 'Slaptažodis turi būti bent 8 simbolių ilgio',
    ph: 'Password need to be al least 8 character long',
    ch: '密码长度必须至少为 8 个字符',
  },
  'Create New Institution': {
    en: 'Create New Institution',
    ja: 'Create New Institution',
    et: 'Create New Institution',
    lt: 'Create New Institution',
    ph: 'Create New Institution',
    ch: 'Create New Institution',
  },
  'Go Here': {
    en: 'Go Here',
    ja: 'Go Here',
    et: 'Go Here',
    lt: 'Go Here',
    ph: 'Go Here',
    ch: 'Go Here',
  },
  'Sign Up For Free': {
    en: 'Sign Up For Free',
    ja: 'Sign Up For Free',
    et: 'Sign Up For Free',
    lt: 'Sign Up For Free',
    ph: 'Sign Up For Free',
    ch: 'Sign Up For Free',
  },
  signUpInstitution: {
    en: 'Looking to get VIVIBOOM for your school/institution?',
    ja: 'Looking to get VIVIBOOM for your school/institution?',
    et: 'Looking to get VIVIBOOM for your school/institution?',
    lt: 'Looking to get VIVIBOOM for your school/institution?',
    ph: 'Looking to get VIVIBOOM for your school/institution?',
    ch: 'Looking to get VIVIBOOM for your school/institution?',
  },
};

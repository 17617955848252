export default {
  tokenSent: {
    en: 'Reset token sent',
    ja: 'Reset token sent',
    et: 'Tühista žetooni saatmine',
    lt: 'Atsiimti išsiųstą žetoną',
    ph: 'Reset token sent',
    ch: 'Reset token sent',
  },
  verifyEmailToken: {
    en: 'Verify Email Token',
    ja: 'Verify Email Token',
    et: 'Verifitseeri žetooni e-mailiaadress',
    lt: 'Patvirtinti žetoną',
    ph: 'Verify Email Token',
    ch: 'Verify Email Token',
  },
  emailSent: {
    en: 'An email has been sent to',
    ja: 'An email has been sent to',
    et: 'Saatsime e-maili sinu aadressile',
    lt: 'Emailas išsiųstas į',
    ph: 'An email has been sent to',
    ch: 'An email has been sent to',
  },
  checkAndProceed: {
    en: 'please check it and proceed from there.',
    ja: 'please check it and proceed from there.',
    et: 'palun vaata üle ja jätka sealt.',
    lt: 'patikrink paštą ir sek nurodymus.',
    ph: 'please check it and proceed from there.',
    ch: 'please check it and proceed from there.',
  },
  verifyEmail: {
    en: 'Verify Email',
    ja: 'メール確認',
    et: 'Verifitseri e-mail',
    lt: 'Patvirtinti emailą',
    ph: 'Verify Email',
    ch: '确认电邮地址',
  },
  verifyEmailText: {
    en: "Before you can sign in you'll need to verify your email. You can type in your email below to receive another verification link.",
    ja: 'ログインする前に、メールアドレスを確認する必要があります。 下にメールを入力して、別の確認リンクを受け取ることができます。',
    et: 'Enne sisselogimist pead verifitseerima oma e-maili aadressi. Sisesta e-mailiaadress, et saaksime saata sulle uue verifitseerimislingi.',
    lt: 'Prieš prisijungiant reikės patvirtinti emailą. Jei nori gauti dar vieną patvirtinimo nuorodą, įrašyk savo emailą žemiau.',
    ph: "Before you can sign in you'll need to verify your email. You can type in your email below to receive another verification link.",
    ch: '在登录之前，请验证你的电子邮件。请键入电子邮件来接收另一个验证链接。',
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-maili aadress',
    lt: 'Emailas',
    ph: 'Email',
    ch: '电邮地址',
  },
  sendVerificationCode: {
    en: 'Send Verification Code',
    ja: '確認コードを送信する',
    et: 'Saada verifitseerimiskood',
    lt: 'Nusiųsti patvirtinimo kodą',
    ph: 'Send Verification Code',
    ch: '传送验证代码',
  },
  resendVerificationCode: {
    en: 'Resend Verification Code',
    ja: '確認コードを再送信する',
    et: 'Saatke verifitseerimiskood',
    lt: 'Iš naujo siųsti patvirtinimo kodą',
    ph: 'Resend Verification Code',
    ch: '重新发送验证码',
  },
  emailSentText: {
    en: 'Email sent, please check your email.',
    ja: 'メールを送信しました。メールをご確認ください。',
    et: 'Kiri saadetud, kontrolli oma e-maile',
    lt: 'Emailas išsiųstas, patikrink savo paštą',
    ph: 'Email sent, please check your email.',
    ch: '电邮地址已传送， 请检查你的邮箱。',
  },
  verifyingToken: {
    en: 'Verifying Token',
    ja: 'トークン確認中',
    et: 'Kinnituskood',
    lt: 'Tikrinamas žetonas',
    ph: 'Verifying Token',
    ch: '验证代码',
  },
  verifyTokenSuccess: {
    en: 'Success, Email verified. Logging you in!',
    ja: '成功、メールを確認しました。 ログインしています！',
    et: 'E-maili aadress verifitseeritud. Sisselogimine.',
    lt: 'Valio, emailas patvirtintas. Prijungiame tave!',
    ph: 'Success, Email verified. Logging you in!',
    ch: '成功， 电邮地址已确认。 正在进行登入。',
  },
  verifyTokenFail: {
    en: 'Oops! The email verification link has expired. Please login below to try again.',
    ja: 'Oops! The email verification link has expired. Please login below to try again.',
    et: 'Oops! The email verification link has expired. Please login below to try again.',
    lt: 'Oops! The email verification link has expired. Please login below to try again.',
    ph: 'Oops! The email verification link has expired. Please login below to try again.',
    ch: 'Oops! The email verification link has expired. Please login below to try again.',
  },
  resetPassword: {
    en: 'Reset Password',
    ja: 'パスワードリセット',
    et: 'Lähtesta Salasõna',
    lt: 'Atstatyti slaptažodį',
    ph: 'Reset Password',
    ch: '重新设置密码',
  },
  resetPasswordText: {
    en: 'Forgot your password? Type in your email below to receive a reset link.',
    ja: 'パスワードをお忘れですか？ リセットリンクを受け取るには、下にメールを入力してください。',
    et: 'Unustasid salasõna? Kirjuta oma e-maili aadress, et saaksime saata sulle salasõna lähtestamise lingi.',
    lt: 'Pamiršai slaptažodį? Įrašyk savo emailą ir gauk atstatymo nuorodą.',
    ph: 'Forgot your password? Type in your email below to receive a reset link.',
    ch: '忘了密码？请键入密码来接收另一个重置链接。',
  },
  sendResetPasswordLinkButton: {
    en: 'Send Reset Password Link',
    ja: 'パスワードリセットリンク送信する',
    et: 'Saada salasõna lähtestamise Link',
    lt: 'Siųsti slaptažodžio atstatymo nuorodą',
    ph: 'Send Reset Password Link',
    ch: '发送重置密码链接',
  },
  sentResetPasswordLink: {
    en: 'Reset Password Email sent! Please check your email.',
    ja: 'パスワードのリセットメールを送信しました！ メールを確認してください。',
    et: 'Salasõna lähtestamise link saadetud. Palun kontrolli oma e-maili.',
    lt: 'Slaptažodžio atstatymo emailas išsiųstas! Patikrink emailą',
    ph: 'Reset Password Email sent! Please check your email.',
    ch: '重置密码的电邮已传送！请检查你的邮箱。',
  },
  setNewPassword: {
    en: 'Set New Password',
    ja: '新しいパスワードを設定',
    et: 'Seadista Uus Salasõna',
    lt: 'Nustatyti naują slaptažodį',
    ph: 'Set New Password',
    ch: '设置新密码',
  },
  newPassword: {
    en: 'New Password',
    ja: '新しいパスワード',
    et: 'Uus Salasõna',
    lt: 'Naujas slaptažodis',
    ph: 'New Password',
    ch: '新密码',
  },
  setPasswordButton: {
    en: 'Set Password',
    ja: 'パスワード設定する',
    et: 'Määra parool',
    lt: 'Nustatyti slaptažodį',
    ph: 'Set Password',
    ch: '设置密码',
  },
  setPasswordSuccess: {
    en: 'Successfully Set New Password!',
    ja: '新しいパスワードを設定しました！',
    et: 'Parooli vahetamine õnnestus',
    lt: 'Sėkmingai nustatei naują slaptažodį!',
    ph: 'Successfully Set New Password!',
    ch: '成功设置新密码',
  },
  verifyEmailChange: {
    en: 'Verify Email Change',
    ja: 'メールの変更確認',
    et: 'Kinnita e-maili aadressi vahetus',
    lt: 'Patvirtinti emailo keitimą',
    ph: 'Verify Email Change',
    ch: '确认更改电邮地址',
  },
  verifyEmailChangeSuccess: {
    en: 'Success! Email Changed. toast, Redirecting you',
    ja: '成功！ メールが変更されました',
    et: 'E-maili vahetus õnnestus, suunan ümber',
    lt: 'Valio, emailas pakeistas.',
    ph: 'Success! Email Changed. toast, Redirecting you',
    ch: '成功！电邮地址已更改。现在将你重定向。',
  },
  tokenInvalid: {
    en: 'Either that token is invalid or expired.',
    ja: 'トークンは無効か期限切れです。',
    et: 'Kood on aegunud või pole õige',
    lt: 'Šis žetonas negalioja.',
    ph: 'Either that token is invalid or expired.',
    ch: '该标记无效或过期。',
  },
  verifyEmailChangeErrorText: {
    en: 'Looks like something went wrong. Please request a new reset password link.',
    ja: '問題が発生したようです。 新しいパスワードのリセットリンクをリクエストしてください',
    et: 'Midagi läks viltu. Küsi endale uus parooli vahetamise link.',
    lt: 'Kažkas ne taip. Paprašyk naujos slaptžodžio atstatymo nuorodos.',
    ph: 'Looks like something went wrong. Please request a new reset password link.',
    ch: '看起来出了点问题。请请求新的重置密码链接。',
  },
  verifyEmailChangeErrorListText1: {
    en: "1. If you're not signed in already, sign in.",
    ja: '1. まだサインインしていない場合は、サインインしてください。',
    et: '1. Kui sa pole veel sisse logitud, logi sisse',
    lt: '1. Jei dar neprisijungei, prisijunk.',
    ph: "1. If you're not signed in already, sign in.",
    ch: '1. 如果尚未登录,请登录',
  },
  verifyEmailChangeErrorListText2: {
    en: '2. Go to My account.',
    ja: '2. マイアカウントに移動します。',
    et: '2. Mine Minu konto-le',
    lt: '2. Eiti į mano paskyrą.',
    ph: '2. Go to My account.',
    ch: '2. 前往“我的户口',
  },
  verifyEmailChangeErrorListText3: {
    en: '3. Click on the edit button next to email.',
    ja: '3. メールの横にある編集ボタンをクリックします。',
    et: '3. Vajuta Muuda nuppu E-maili juures',
    lt: '3. Spausk mygtuką Redaguoti, šalia emailo.',
    ph: '3. Click on the edit button next to email.',
    ch: '3. 单击电子邮件旁边的编辑按钮。',
  },
  verifyEmailChangeErrorListText4: {
    en: "4. Type in the email you'd like to change your account to.",
    ja: '4. アカウントを変更するメールを入力します。',
    et: '4. Siseta e-maili aadress, mida soovid edaspidi kasutada',
    lt: '4. Įrašyk emailą, kuris nuo šiol bus prijungtas prie tavo paskyros.',
    ph: "4. Type in the email you'd like to change your account to.",
    ch: '4. 键入要更改帐户的电子邮件。',
  },
};

export const EventOrderType = {
  LATEST: 'LATEST',
  OLDEST: 'OLDEST',
  RANDOM: 'RANDOM',
};

export const eventOrderTypes = [
  EventOrderType.LATEST,
  EventOrderType.OLDEST,
  EventOrderType.RANDOM,
];
